import * as React from 'react';
import {
  formatTime,
  getLowestPrice,
  isEventPublic,
  isSalesEnded,
  isEventLive,
  formatDateTimeWithTimeZone,
} from '@/lib/index';
import FadeIn from 'react-fade-in';
import { useEffect, useState } from 'react';
import algoliasearch from 'algoliasearch';
import { EventProps } from '@/modules/data/types/Event';
import { createLogger } from '@/modules/logging/logger';
import Link from 'next/link';
import Tags from '../shared/Tags';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { AMPLITUDE_ACTIONS, AMPLITUDE_EVENTS } from '@/modules/amplitude/events';
import { trackAction } from '@/modules/amplitude/amplitudeBrowserClient';

const logger = createLogger({
  fileLabel: `components/Event/Events`,
});

const client = algoliasearch(process.env.ALGOLIA_ID, process.env.ALGOLIA_KEY);
const index = client.initIndex('events');

function useMounted() {
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);
  return mounted;
}
interface MyEventsProps {
  events: EventProps[];
}

export const Events: React.FunctionComponent<MyEventsProps> = ({ events }) => {
  const isMounted = useMounted();
  const [eventResults, setEventResults] = useState<EventProps[]>(events);
  const [query, setQuery] = useState<string>('');
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [startSearching, setStartSearching] = useState<boolean>(false);

  const [activeCategories, setActiveCategories] = useState([]);
  const [categories, setCategories] = useState([
    { title: 'shows', active: false },
    { title: 'party', active: false },
    { title: 'dj', active: false },
    { title: 'arts', active: false },
    { title: 'talks', active: false },
    {
      title: 'seattle',
      active: false,
    },
  ]);

  const [activeLocations, setActiveLocations] = useState([]);
  const [categoryParent] = useAutoAnimate();
  const [locationParent] = useAutoAnimate();

  const [locations, setLocations] = useState([
    {
      title: 'Seattle',
    },
    {
      title: 'New York',
      state: 'NY',
    },
    {
      title: 'Texas',
      state: 'TX',
    },
    {
      title: 'Los Angeles',
    },
    {
      title: 'DC',
      state: 'DC',
    },
    {
      title: 'Portland',
    },
  ]);

  useEffect(() => {
    if (activeLocations.length > 0 || activeCategories.length > 0) {
      filterEvents();
    } else {
      setEventResults(events);
    }
  }, [activeLocations, activeCategories]);

  const onActiveCategory = (item) => {
    if (activeCategories.some((e) => e.title === item.title)) {
      return;
    } else {
      setActiveCategories([...activeCategories, item]);
      setCategories(categories.filter((a) => a.title !== item.title));
    }
  };

  const onInActiveCategory = (item) => {
    setCategories([...categories, item]);
    setActiveCategories(activeCategories.filter((a) => a.title !== item.title));
  };

  const onActiveLocation = (item) => {
    if (activeLocations.some((e) => e.title === item.title)) {
      return;
    } else {
      setActiveLocations([...activeLocations, item]);
      setLocations(locations.filter((a) => a.title !== item.title));
    }
  };

  const onInActiveLocation = (item) => {
    setLocations([...locations, item]);
    setActiveLocations(activeLocations.filter((a) => a.title !== item.title));
  };

  const filterEvents = () => {
    if (activeLocations.length > 0) {
      const filteredEvents = events.filter((event) => {
        return activeLocations.some((location) => {
          return event.location.city === location.title || event.location.state === location.state;
        });
      });
      setEventResults(filteredEvents);
    } else {
      return events;
    }
  };
  const getEvents = (query) => {
    index
      .search(query)
      .then(({ hits }: any) => {
        setIsSearching(false);
        setEventResults(hits.filter((hit) => hit.public));
      })
      .catch((err) => {
        setIsSearching(false);
        logger.error(err);
      });
  };

  return (
    <div className={`pv3 mw9`}>
      <div className=" ml4-ns ">
        <h1 className="f1-ns f2 font-bold font-grotesk-md pb3 mb2">Find upcoming events near you </h1>
        <div className="bg-near-black overflow-hidden pa2 pa3-ns br3 ">
          <div className="mb-3 flex py-1 ph2 bb border-gray-400 md:w-2/4 w-full">
            <svg
              className="white inline text-gray-400   pa1"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24">
              <path d="M23.822 20.88l-6.353-6.354c.93-1.465 1.467-3.2 1.467-5.059.001-5.219-4.247-9.467-9.468-9.467s-9.468 4.248-9.468 9.468c0 5.221 4.247 9.469 9.468 9.469 1.768 0 3.421-.487 4.839-1.333l6.396 6.396 3.119-3.12zm-20.294-11.412c0-3.273 2.665-5.938 5.939-5.938 3.275 0 5.94 2.664 5.94 5.938 0 3.275-2.665 5.939-5.94 5.939-3.274 0-5.939-2.664-5.939-5.939z" />
            </svg>

            <input
              className="pa2 bt-0 br-0 bl-0 input-reset  bg-transparent white md:w-80 w-70 mr3"
              placeholder="Search events"
              value={query}
              onChange={(e) => {
                setQuery(e.currentTarget.value);
                getEvents(e.currentTarget.value);

                if (!startSearching) {
                  setStartSearching(true);
                  trackAction({
                    action: AMPLITUDE_ACTIONS.CLICK,
                    event: AMPLITUDE_EVENTS.EVENT_CATEGORY_FILTER_USED,
                    opts: { searchEvents: e.currentTarget.value },
                  });
                }
              }}
            />
          </div>

          <div className="flex relative flex-col mt-2">
            <h3 className="  text-xl font-semibold">Locations</h3>
            <div>
              <ul ref={locationParent} className="flex   text-md flex-row py-2  flex-wrap">
                {activeLocations?.length > 0 && (
                  <>
                    {activeLocations?.map((item, idx) => (
                      <div>
                        <Tags
                          className={`cursor-pointer mr-[10px] mb-[10px] py-1 w-fit  px-3 rounded-full  flex items-center bg-[#686868] text-white font-medium `}
                          onClick={() => {
                            trackAction({
                              action: AMPLITUDE_ACTIONS.CLICK,
                              event: AMPLITUDE_EVENTS.EVENT_CATEGORY_SELECTED_LOCATION,
                              opts: {
                                location: item.title,
                              },
                            });

                            onInActiveLocation(item);
                          }}>
                          <div className="flex gap-[3px]">
                            <p className=" whitespace-nowrap">{item.title}</p>
                            <div>
                              <svg
                                width="8"
                                className="inline-block"
                                height="8"
                                viewBox="0 0 15 16"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.5 9.59696L1.91065 15.1863C1.70152 15.3954 1.43536 15.5 1.11217 15.5C0.788973 15.5 0.522813 15.3954 0.313688 15.1863C0.104562 14.9772 0 14.711 0 14.3878C0 14.0646 0.104562 13.7985 0.313688 13.5894L5.90304 8L0.313688 2.41065C0.104562 2.20152 0 1.93536 0 1.61217C0 1.28897 0.104562 1.02281 0.313688 0.813688C0.522813 0.604562 0.788973 0.5 1.11217 0.5C1.43536 0.5 1.70152 0.604562 1.91065 0.813688L7.5 6.40304L13.0894 0.813688C13.2985 0.604562 13.5646 0.5 13.8878 0.5C14.211 0.5 14.4772 0.604562 14.6863 0.813688C14.8954 1.02281 15 1.28897 15 1.61217C15 1.93536 14.8954 2.20152 14.6863 2.41065L9.09696 8L14.6863 13.5894C14.8954 13.7985 15 14.0646 15 14.3878C15 14.711 14.8954 14.9772 14.6863 15.1863C14.4772 15.3954 14.211 15.5 13.8878 15.5C13.5646 15.5 13.2985 15.3954 13.0894 15.1863L7.5 9.59696Z" />
                              </svg>
                            </div>
                          </div>
                        </Tags>
                      </div>
                    ))}
                  </>
                )}

                {locations.map((item, idx) => (
                  <div key={idx} className="flex flex-row mb-[10px] items-center mr-[10px]">
                    <Tags
                      className={`cursor-pointer py-1 px-3 rounded-full h-fit flex items-center bg-[#6AA543]  text-white font-medium w-fit`}
                      onClick={() => {
                        onActiveLocation(item);

                        trackAction({
                          action: AMPLITUDE_ACTIONS.CLICK,
                          event: AMPLITUDE_EVENTS.EVENT_CATEGORY_FILTER_LOCATION_USED,
                          opts: {
                            location: item.title,
                          },
                        });
                      }}>
                      <p className=" whitespace-nowrap">{item.title}</p>
                    </Tags>
                  </div>
                ))}
              </ul>
            </div>
          </div>
          {isMounted && !isSearching ? (
            <FadeIn>
              <div className=" grid lg:grid-cols-3  sm:grid-cols-2 grid-cols-1 gap-4 my-8 ">
                {eventResults
                  .filter((event) => isEventPublic(event) && isEventLive(event))
                  .sort((a, b) =>
                    new Date(a.startDate) === new Date(b.startDate)
                      ? 0
                      : new Date(a.startDate) < new Date(b.startDate)
                      ? 1
                      : -1,
                  )
                  .map((curr: EventProps, ind) => {
                    const {
                      url,
                      imageWide,
                      image,
                      slug,
                      name,
                      startDate,
                      endDate,
                      location: { timeZoneId },
                    } = curr;

                    const urlWork = url ? `http://${url}?utm_source=whatstba.com&utm_medium=home_page` : `/e/${slug}`;
                    return (
                      <div className="w-100 mb3" key={ind}>
                        <Link href={urlWork} legacyBehavior>
                          <span className="white no-underline">
                            {/* <Image
                                className="db mw-100 object-cover "
                                src={imageWide || image}
                                width={438}
                                height={219}
                                alt={name}
                              /> */}

                            <div
                              className="w-full h-auto relative flex md:min-h-[282px] min-h-[120px] "
                              style={{ boxSizing: 'inherit' }}>
                              <img className=" w-full h-auto sm:object-cover sm:h-[282px]" src={imageWide || image} />
                            </div>
                            <h4 className="f5 font-bold uppercase mt2 text-gray-500">
                              {formatDateTimeWithTimeZone(new Date(startDate), 'home', timeZoneId)}
                              {' — '}
                              {formatTime(new Date(startDate), timeZoneId)}
                            </h4>
                            <h4 className="f4 fw8 mt2 mb0 ttu font-grotesk-md">{curr.name}</h4>
                            <h4 className="f5 fw6 mt1 pt1 ">
                              {`${getLowestPrice(curr.ticketTypes, curr.currency).toString()}
														- ${curr.location.city}, ${curr.location.state} `}
                            </h4>
                          </span>
                        </Link>
                        <div className="mt3">
                          <Link href={urlWork} legacyBehavior>
                            <span className="bg-black white bw1 ba br-100 pa2 tc f4-ns f6 fw6-ns fw5 grow no-underline ph4 b--solid ">
                              {!isSalesEnded(curr.endDate) ? `Get Tickets` : `Sales Ended`}
                            </span>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </FadeIn>
          ) : (
            <div className="f1 tc">
              <i className="fa-2x fa fa-spinner fa-spin  " />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
