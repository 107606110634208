import React, { useState } from 'react';
import axios from 'axios';
import { createLogger } from '@/modules/logging/logger';

const logger = createLogger({
  fileLabel: `components/shared/Upload`,
});
interface EventImageProps {
  image?: string;
  setFile?: any;
  refName?: string;
  buttonText?: string;
  getUploadedFields?: (url: string, name: string) => void;

  multiple?: boolean;
  types?: string[];
  folder?: string;
}

export const Upload: React.FunctionComponent<EventImageProps> = ({
  setFile,
  image,
  refName,
  buttonText,
  multiple,
  types,
  folder,
  getUploadedFields,
}) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const processUpload = async (e: File) => {
    setUploading(true);

    const formData = new FormData();
    formData.append('file', e);
    formData.append('resource_type', 'auto');
    formData.append('upload_preset', 'wfrfnmis');

    if (folder) {
      formData.append('folder', folder);
    }

    try {
      const { data } = await axios(`https://api.cloudinary.com/v1_1/dzsf703vh/image/upload`, {
        method: 'POST',
        data: formData,
      });
      setUploading(false);
      setUploaded(true);
      return data;
    } catch (e) {
      logger.error(e);
      setUploading(false);
    }
  };

  const beforeUpload = (file: File) => {
    if (!file) return;
    const validTypes = new Set();
    if (types) {
      types.forEach(validTypes.add, validTypes);
    } else {
      ['image/png', 'image/jpeg'].forEach(validTypes.add, validTypes);
    }
    if (!validTypes.has(file.type)) {
      setError(`You can only upload ${validTypes.entries()}} file!`);
      console.error(`You can only upload ${validTypes.entries()}} file!`);
    } else {
      setError('');
    }
    const isLt5M = file.size / 1024 / 1024 < 8;
    if (!isLt5M) {
      setError('File must smaller than 8MB!');
      console.error('File must smaller than 8MB!');
    } else {
      setError('');
    }
    return isLt5M;
  };
  const handleChange = async (selectorFiles: FileList) => {
    const arr = Array.from(selectorFiles).map(async (file) => {
      if (beforeUpload(file)) return processUpload(file);
    });
    const results = await Promise.all(arr);
    if (multiple) {
      setFile(results);
    } else {
      logger.info('res', results);
      if (setFile) {
        setFile(results[0].secure_url);
      } else {
        getUploadedFields(results[0], refName);
      }
    }
  };
  return (
    <div className="mt4 relative overflow-hidden dib">
      <button
        className="ba bw1 cursor-pointer border-2 border-white b--black dib noselect br-100 b--solid pa2 ph3 f5 fw5"
        onClick={(e) => {
          e.preventDefault();
          document.getElementById('Upload').click();
        }}>
        {uploading && <i className="fa fa-spinner fa-spin mr2" />}
        {uploading && 'Uploading...'}
        {!uploading && uploaded && `Uploaded! Replace?`}
        {image && !uploaded && `Replace ${buttonText}`}
        {!uploaded && !uploading && !image && `Upload ${buttonText}`}
      </button>
      <input
        type="file"
        multiple={multiple}
        id="Upload"
        onChange={(e) => handleChange(e.target.files)}
        style={{ left: 0, top: 0 }}
        className="absolute o-0"
      />
      <div className={`mt2`}>{error}</div>
    </div>
  );
};
