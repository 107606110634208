import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import Reward from 'react-rewards';
import {
  formatEventTime,
  stripeClient,
  isEventInFuture,
  formatDateTimeWithTimeZone,
  getTotalOrderRev,
  serverDomain,
  areAllTicketsFree,
  isEventNotPublished,
  stripeFee,
} from '@/lib/index';
import { TicketCheckoutForm, UserCheckoutForm } from './';
import classnames from 'classnames';
import { PaymentCheckoutForm } from './PaymentCheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import customId from 'r2-custom-id';
import axios from 'axios';
import { createLogger } from '@/modules/logging/logger';
import { EventProps, OrderProps, UserTicketProps, OfferProps, EventCartProps } from '@/modules/data/types/';
import { CheckoutFlow } from '@/lib/constants';
import styled from 'styled-components';
import classNames from 'classnames';
import Lottie from 'react-lottie';
import animationData from '@/static/animations/diced.json';
import Image from 'next/legacy/image';
import { purchaseEvent, trackEvent } from '@/modules/gtag';
import { PasswordModal } from './PassswordModal';
import { SmsProps } from '@/modules/twilio';
import { useRouter } from 'next/router';
import getStripe from '@/modules/stripe';
import { Tooltip } from 'react-tooltip';
import { CopyIcon, FacebookIcon, ShareIcon, TwitterIcon } from '../shared/Icons';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Country } from 'react-phone-number-input';
import { trackAction } from '@/modules/amplitude/amplitudeBrowserClient';
import { AMPLITUDE_ACTIONS, AMPLITUDE_EVENTS } from '@/modules/amplitude/events';
import urlencode from 'urlencode';
const logger = createLogger({
  fileLabel: `components/Event/Event`,
});

interface EventViewProps {
  event: EventProps;
}

const ParentDiv = styled.div`
  > p > span {
    background-color: black !important;
  }
`;
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const scrollTo = (ref) => {
  if (ref && ref.current /* + other conditions */) {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};
export const Event: React.FunctionComponent<EventViewProps> = ({ event }) => {
  const [step, setStep] = useState<CheckoutFlow>(CheckoutFlow.EVENT_VIEW);
  const [cart, setCart] = useState<{
    [ticketName: string]: EventCartProps;
  }>({});
  const [order, setOrder] = useState<OrderProps>(null);
  const [orderId] = useState<string>(
    customId({
      eventId: event._id,
      orderDate: new Date(),
    }),
  );
  const [offers, setOffers] = useState<{
    [couponCode: string]: OfferProps;
  }>(event.offers);
  const [total, setTotal] = useState<number>(0);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');
  const [offerCode, setOfferCode] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  // const [trackUrl, setTrackUrl] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [checkoutQuestions, setCheckoutQuestions] = useState<{}>('');
  // const [readMore, setReadMore] = useState<boolean>(false);
  const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
  const stripePromise = getStripe();
  const ticketsRef = useRef(null);
  const [extraFields, setExtraFields] = useState<{ [field: string]: string }[]>([]);
  // const showReadMore =
  //   (parse(event.description) as Array<any>)?.filter((p) => typeof p !== 'string')?.length > 8 ? true : false;
  const [clientSecret, setClientSecret] = useState<string>(null);
  const [textTickets, setTextTickets] = useState<boolean>(false);
  const [waitlist, setWaitlist] = useState<boolean>(false);
  const [offerBased, setOfferBased] = useState<boolean>(false);
  const [autoAccept, setAutoAccept] = useState<boolean>(false);
  const [fetchStripe, setFetchStripe] = useState<boolean>(false);
  const [openShare, setOpenShare] = useState<boolean>(false);
  const [copied, setCopied] = useState(false);
  const [showPageLink, setShowPageLink] = useState(false);
  const [shareParent, enableAnimations] = useAutoAnimate({ easing: 'ease-out', duration: 300 });
  const myRef = useRef(null);
  const reward = useRef(null);
  const router = useRouter();

  const utm_source = router.query?.utm_source as string;
  const utm_medium = router.query?.utm_medium as string;

  const executeScroll = () => myRef?.current?.scrollIntoView();
  useEffect(() => {
    if (total) {
      logger.info(createOrder({}));

      prepareCheckout();
    }
  }, [total]);

  useEffect(() => {
    if (event.password) {
      setShowPasswordModal(true);
    }
  }, [event.password]);

  useEffect(() => {
    import(`react-facebook-pixel`)
      .then((x) => x.default)
      .then((ReactPixel) => {
        if (event.slug === 'techsgiving') {
          logger.info('yerr');
          ReactPixel.init('378782229785589'); // facebookPixelId
          ReactPixel.pageView();
        }

        //  router.events.on(routeChangeComplete, () => {
        //    ReactPixel.pageView();
        //  });
      })
      .catch((e) => logger.error(e));
  }, []);

  const hasRefundPolicy = event.refunds;
  const hasLineUp = Object.keys(event.lineUp).length;
  const hasPhotos = event.photos.length;
  const confirmationHeader = () => {
    if (waitlist || event.approvalNeeded) {
      return `You are on the waitlist!`;
    } else if (order?.cleared) {
      return `Thanks for your order!`;
    }
    return `You've been placed on the waitlist`;
  };
  const confirmationSupportText = () => {
    if (waitlist) {
      return `Once a spot opens up make sure to move fast before they're gone!`;
    } else if (event.approvalNeeded) {
      return `Keep your eyes peeled – event organizers will review your RSVP soon. ${
        order.total
          ? `We'll initiate a hold that lasts for up to 7 days; your card will be charged only if your ${
              waitlist || event.approvalNeeded ? `order` : `offer`
            } is accepted.`
          : ``
      }`;
    } else if (order?.cleared) {
      return `We've sent a confirmation email with details to your email.`;
    }
    return `We will place a hold to reserve funds now for up to 7 days, but only charge your card if your offer is accepted.`;
  };

  const sendSms = async () => {
    const smsData: SmsProps = {
      to: [{ phoneNumber, firstName }],
      marketing: false,
      message: `Hey ${firstName}, ${event.name} is happening on ${formatDateTimeWithTimeZone(
        event.startDate,
        'short-no-year',
        event.location.timeZoneId,
      )} - Here are your tickets! 🎟️ ${serverDomain}/tix/${event._id}/order/${order._id}`,
      slack: { enable: false },
    };
    setTextTickets(true);
    trackEvent({ action: 'text_tix_order', category: 'event_page', label: 'Text your tickets' });
    await axios.post('/api/twilio', {
      smsData,
    });
  };
  const ShareText = `I'm headed to ${event?.name} on @whatsba https://www.whatstba.com/e/${event?.slug}`;
  const ShareUrl = `https://www.whatstba.com/e/${event?.slug}`;
  const hasDescription = () => {
    return event.description ===
      '<p><br /> <br/>Enter in some information and details about your event here...<br /><br/> <br /></p>'
      ? false
      : event.description === '<p>Enter in some information and details about your event here...</p>'
      ? false
      : event.description === '<p><br></p>'
      ? false
      : true;
  };

  const setMode = (step: number) => {
    setStep(step);
    if (step === CheckoutFlow.TICKET_SELECTION) {
      setClientSecret('');
      trackEvent({ action: 'show_tickets', category: 'event_page', label: 'Get your tickets' });
    }
    if (reward.current && step === CheckoutFlow.CONFIRMATION) {
      reward.current.rewardMe();
    }
  };

  const createOrder = (xtraFields) => {
    const { ticketTypes } = event;
    const tickets = [] as UserTicketProps[];
    let isOfferBasedTix = false;
    Object.keys(cart).forEach((curr) => {
      const tix = cart[curr];
      for (let i = 0; i < tix.quantity; i++) {
        const tempTix = ticketTypes[tix.ticketName];
        if (tempTix.ticketType === 'offer' && !tempTix.autoAcceptOffers) {
          isOfferBasedTix = true;
          setOfferBased(true);
        }
        order;
        tickets.push({
          ticketName: tempTix.ticketName,
          fee: cart[curr].fee,
          price: cart[curr].price,
          description: tempTix.description,
          guest: false,
          barCode: curr,
          orderId,
          eventId: event._id,
          checkedIn: null,
          checkInDate: null,
        });
      }
    });
    const newOrder: OrderProps = {
      emailAddress,
      firstName,
      updatedAt: new Date(),
      lastName,
      zipCode,
      eventId: event._id,
      currency: event.currency ?? 'USD',
      _id: orderId,
      token: null,
      guest: false,
      phoneNumber,
      extraFields: xtraFields,
      checkedIn: false,
      refunded: false,
      cancelled: false,
      cleared: (offerBased || isOfferBasedTix || event.approvalNeeded) && !autoAccept ? false : true,
      status: (offerBased || isOfferBasedTix || event.approvalNeeded) && !autoAccept ? 'pending' : 'copped',
      tickets,
      stripeFee: total ? parseFloat(stripeFee(total, event.currency).toFixed(2)) : 0,
      total,
      offerCode,
      offerBased,
      orderDate: new Date(),
      cart,
    };
    setOrder(newOrder);
    return newOrder;
  };

  const buttonText = () => {
    if (event.slug === 'royal&rare') {
      return `RSVP`;
    } else if (isEventNotPublished(event)) {
      return `Sale Not Started`;
    } else if (!isEventInFuture(event)) {
      return 'Sale Ended';
    } else if (step === CheckoutFlow.EVENT_VIEW) {
      return areAllTicketsFree(event) ? 'Get Tickets' : 'Buy Tickets';
    } else {
      return 'Cancel';
    }
  };

  const prepareCheckout = async () => {
    if (total > 0) {
      const response = await axios.post('/api/stripe', {
        order: {
          ...order,
          total,
          _id: orderId,
          cart,
          status: 'copped',
        },
        event,
      });

      if (response.data.statusCode === 500) {
        // Please clarify what `logger` refers to and add documentation if necessary
        logger.error(response.data.message);
        return;
      }

      // Please clarify what `setClientSecret` refers to and add documentation if necessary
      const {
        data: { client_secret },
      } = response;
      setClientSecret(client_secret);

      // Please be consistent with return statements in the function
      return client_secret;
    }
  };

  const onShare = () => {
    if (navigator?.share) {
      //Web Share API
      navigator
        .share({
          title: `WhatsTba - ${event?.name}`,
          url: `https://www.whatstba.com/e/${event?.slug}`,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      //Fallback
      setShowPageLink(true);
    }
  };
  const getApplePayOrder = ({}) => {
    return createOrder({});
  };
  const handleCheckout = async (xtraFields) => {
    setExtraFields(xtraFields);
    try {
      setMode(CheckoutFlow.PROCESSING);

      if (waitlist) {
        const waitEntry = {
          _id: customId({
            eventId: event._id,
            firstName,
            lastName,
            orderDate: new Date(),
          }),
          firstName,
          lastName,
          emailAddress,
          waitDate: new Date(),
          updatedAt: new Date(),
          phoneNumber,
          eventId: event._id,
          cleared: false,
          status: 'pending',
        };
        await axios.post('/api/joinWaitlist', {
          waitEntry,
          event,
        });
        setMode(CheckoutFlow.CONFIRMATION);
      } else {
        const newOrder = createOrder(xtraFields);

        if (total === 0) {
          await axios.post(
            '/api/ticket',
            {
              order: {
                ...newOrder,
                status: (offerBased && !autoAccept) || event.approvalNeeded ? 'pending' : 'copped',
              },
              event,
            },
            {
              params: { utm_source, utm_medium },
            },
          );
          purchaseEvent({
            first_name: newOrder.firstName,
            last_name: newOrder.lastName,
            postal_code: newOrder.zipCode,
            affiliation: event.name,
            tax: 0,
            email_address: newOrder.emailAddress,
            value: getTotalOrderRev([newOrder]),
            cart: newOrder.cart,
          });
          setMode(CheckoutFlow.CONFIRMATION);
        } else {
          setMode(CheckoutFlow.PAYMENT_CHECKOUT);
        }
      }
    } catch (error) {
      // handle errors gracefully
      logger.error('Ticket creation error on purchase', error);
      setMode(CheckoutFlow.ERROR);
    }
  };

  return (
    <main className="mw9 mt4 pt4 center">
      <PasswordModal
        isOpen={showPasswordModal}
        closeModal={() => setShowPasswordModal(false)}
        password={event.password}
      />
      <section className="mh4-ns md:mh3  overflow-hidden">
        <div className="relative h-2 mb-3">
          <Image alt="" layout="fill" src="/static/images/tba-strip-basic.png" />
        </div>

        {event.imageWide && (
          // <Image
          //   alt=""
          //   objectFit="cover"
          //   objectPosition="top"
          //   src={event.imageWide}
          //   width={'2160'}
          //   height={'1080'} // alt=""
          // />
          <div className="  md:max-h-[800px] ">
            <img
              src={event.imageWide}
              className={`h-auto w-full md:max-h-[800px] md:object-cover md:object-top lg:min-h-[200px] `}
            />
          </div>
        )}
      </section>
      <article className="ph4-ns ph3  dt-ns tc tl-ns w-90-l w-100 mt3">
        <div className="dtc-l  pt2-m  v-mid f3-l f5 fw7">
          <div className=" lh-title mb0 mt0-ns underline-hover">
            <a className="white no-underline f2-ns f3 text-2xl">{event.name}</a>
          </div>

          <div className="flex justify-between px-4 pt-6 gap-x-16">
            <div className="text-left mb-5  ">
              <p className="gray text-md  ">Location</p>
              {!!!event.online && (
                <>
                  <span className=" underline-offset-4">
                    <a
                      className="  white font-medium mb-1 mt-2 block"
                      target="_blank"
                      href={`https://www.google.com/maps/place/?q=place_id:${event.location.placeId}`}>
                      {event.location.venue}
                    </a>
                  </span>
                  <a
                    className=" font-light gray  underline-offset-4 font-medium"
                    target="_blank"
                    href={`https://www.google.com/maps/place/?q=place_id:${event.location.placeId}`}>
                    {event.location.address.length
                      ? `${event.location.address}. ${event.location.city}, ${event.location.state} ${event.location.zip} `
                      : `${event.location.city}, ${event.location.state}`}
                  </a>
                </>
              )}
              {!!event.online && <p className="mb-1 mt-2 font-medium ">Online</p>}
            </div>
            <div className="text-left  mb-5">
              <p className="gray text-md">Date</p>
              <p className="mb-1 mt-2 font-medium ">
                {`${formatDateTimeWithTimeZone(new Date(event.startDate), 'date', event.location.timeZoneId)}`}
              </p>
              <p className="gray font-medium">
                {`${formatEventTime(new Date(event.startDate), new Date(event.endDate), event.location.timeZoneId)}`}
              </p>
            </div>
          </div>
        </div>
        {step < CheckoutFlow.PAYMENT_CHECKOUT && (
          <div className="dtc-l v-mid tr-l tc f2-l f3 fw6">
            <button
              id="btn-ticket-action"
              ref={myRef}
              data-testid="open-tixs"
              onClick={() => {
                trackAction({
                  action: AMPLITUDE_ACTIONS.CLICK,
                  event: AMPLITUDE_EVENTS.CHECKOUT_VIEW_TICKETS,
                  opts: {
                    orderId: order?._id,
                    orderEventId: order?.eventId,
                    orderTicketName: order?.ticketName,
                    orderFirstName: order?.firstName,
                    orderLastName: order?.lastName,
                    orderEmailAddress: order?.emailAddress,
                    orderTotal: order?.total,
                  },
                  eventData: {
                    eventId: event?._id,
                    eventName: event?.name,
                  },
                });
                return step === 0 && isEventInFuture(event)
                  ? setMode(CheckoutFlow.TICKET_SELECTION)
                  : setMode(CheckoutFlow.EVENT_VIEW);
              }}
              className={`dib-l ${classnames({
                'bg-green': step === CheckoutFlow.EVENT_VIEW && isEventInFuture(event),
                'bg-red': step > CheckoutFlow.EVENT_VIEW || !isEventInFuture(event),
                'bg-animate': step >= CheckoutFlow.EVENT_VIEW,
              })} no-underline white  noselect br-100 tc pa2 mr2 mt2-l ph4 mt2 fw6`}>
              {buttonText()}
            </button>
          </div>
        )}
      </article>
      <div className="flex flex-wrap my-8 justify-between w-100 nr3 mb3 pb3 ">
        {step !== 0 && (
          <div className=" w-full bg-tbaBlue my-10 md:my-5 py-0 black">
            <div className="tc z-20">
              <Reward
                ref={(ref) => {
                  reward.current = ref;
                }}
                config={{ spread: 150, lifetime: 400, elementCount: 350 }}
                type="confetti"
              />
            </div>{' '}
            <Elements stripe={stripePromise}>
              <div
                className={` mx-auto p-6 py-8 rounded-md  text-gray-01 w-fit ${classnames({
                  dn: step !== CheckoutFlow.PAYMENT_CHECKOUT,
                })}`}>
                <div>
                  <FadeIn>
                    <div className="pl4-ns md:pl-0 ">
                      <div className="mx-auto text-center text-3xl w-full font-extrabold">Checkout</div>
                    </div>
                    <PaymentCheckoutForm
                      mode={step}
                      setMode={setMode}
                      total={total}
                      event={event}
                      cart={cart}
                      offerBased={offerBased}
                      autoAccept={autoAccept}
                      order={order}
                      clientSecret={clientSecret}
                      utm_medium={utm_medium}
                      utm_source={utm_source}
                    />
                  </FadeIn>
                </div>
              </div>
            </Elements>
            {step === CheckoutFlow.CONFIRMATION && (
              <div className="w-100  dib">
                <FadeIn>
                  <div id="confirmation-header" className="pv4 text-gray-01 mb4 f3-ns md:px-0 px-3 f4 tc lh-title">
                    <div className="mv1 text-gray-01 fw6 f1-ns  f2">{confirmationHeader()}</div>
                    {!!order?.cleared && (
                      <span className="b">
                        Order <span className=" no-underline">{`#${order?._id}`}</span>
                      </span>
                    )}

                    <strong>
                      <p className="w-70 center text-center">{confirmationSupportText()}</p>
                      {/* <p className="gray">Questions? Text support to 411</p> */}
                      <div className="tc mt2 pt2 flex  w-[240] mx-auto items-center justify-center md:inline-flex md:flex-row flex-col md:pt-5 pt-9 md:gap-[20px]">
                        {phoneNumber && order?.cleared && (
                          <button
                            onClick={() => {
                              trackAction({
                                action: AMPLITUDE_ACTIONS.CLICK,
                                event: AMPLITUDE_EVENTS.RESEND_TIX,
                                opts: {
                                  orderId: order?._id,
                                  orderEventId: order?.eventId,
                                  orderTicketName: order?.ticketName,
                                  orderFirstName: order?.firstName,
                                  orderLastName: order?.lastName,
                                  orderEmailAddress: order?.emailAddress,
                                  orderTotal: order?.total,
                                },
                                eventData: {
                                  eventId: event?._id,
                                  eventName: event?.name,
                                },
                              });
                              sendSms();
                            }}
                            className=" b--white transition-all hover:text-black  hover:bg-white  noselect ba bw2 md:mr-2 md:mb-0  br-100 b--solid pa2 ph4 f4 fw5">
                            {textTickets ? `Sent!` : `Text your tickets`}
                          </button>
                        )}
                        {order?.cleared && (
                          <a
                            onClick={() => {
                              trackEvent({
                                action: 'open_tix_order',
                                category: 'event_page',
                                label: 'Open your tickets',
                              });
                              trackAction({
                                action: AMPLITUDE_ACTIONS.CLICK,
                                event: AMPLITUDE_EVENTS.OPEN_TICKETS,
                                opts: {
                                  orderId: order?._id,
                                  orderEventId: order?.eventId,
                                  orderTicketName: order?.ticketName,
                                  orderFirstName: order?.firstName,
                                  orderLastName: order?.lastName,
                                  orderEmailAddress: order?.emailAddress,
                                  orderTotal: order?.total,
                                },
                                eventData: {
                                  eventId: event?._id,
                                  eventName: event?.name,
                                },
                              });
                            }}
                            href={`/tix/${event._id}/order/${order?._id}`}
                            target="_blank"
                            className=" b--white mt-[30px] md:mt-0 transition-all hover:text-black  hover:bg-white  noselect ba bw2 br-100 b--solid pa2 ph4 f4 fw5">
                            Open your tickets
                          </a>
                        )}
                      </div>

                      <div className="flex flex-col mt-5">
                        <p className=" text-2xl font-semibold text-center mb-2">Spread the word</p>
                        <div className="flex gap-4 justify-center">
                          <a
                            className="cursor-pointer"
                            target="_blank"
                            rel="no-opener"
                            href={`https://twitter.com/intent/tweet?text=${urlencode(ShareText)}`}>
                            <div className="  items-center ">
                              <TwitterIcon rounded />
                            </div>
                          </a>
                          {/* <a
                            className="cursor-pointer"
                            target="_blank"
                            rel="no-opener"
                            href={`https://www.facebook.com/sharer/sharer.php?quote=${ShareText}`}>
                            <div className="  items-center ">
                              <FacebookIcon />
                            </div>
                          </a> */}

                          <div>
                            <div
                              onClick={() => {
                                trackAction({
                                  action: AMPLITUDE_ACTIONS.CLICK,
                                  event: AMPLITUDE_EVENTS.EVENT_SHARE,
                                  opts: {
                                    orderId: order?._id,
                                    orderEventId: order?.eventId,
                                    orderTicketName: order?.ticketName,
                                    orderFirstName: order?.firstName,
                                    orderLastName: order?.lastName,
                                    orderEmailAddress: order?.emailAddress,
                                    orderTotal: order?.total,
                                  },
                                  eventData: {
                                    eventId: event?._id,
                                    eventName: event?.name,
                                  },
                                });
                                onShare();
                              }}
                              className="w-[48px] group hover:bg-white cursor-pointer h-[48px] rounded-full border-white border-[2.2px] flex items-center justify-center">
                              <ShareIcon />
                            </div>
                          </div>
                        </div>
                        <div ref={shareParent}>
                          {showPageLink && (
                            <div className="w-[400px]  mt-3 mx-auto md:block hidden">
                              <div
                                onClick={() => {
                                  trackAction({
                                    action: AMPLITUDE_ACTIONS.CLICK,
                                    event: AMPLITUDE_EVENTS.EVENT_COPY_LINK,
                                    opts: {
                                      orderId: order?._id,
                                      orderEventId: order?.eventId,
                                      orderTicketName: order?.ticketName,
                                      orderFirstName: order?.firstName,
                                      orderLastName: order?.lastName,
                                      orderEmailAddress: order?.emailAddress,
                                      orderTotal: order?.total,
                                    },
                                    eventData: {
                                      eventId: event?._id,
                                      eventName: event?.name,
                                    },
                                  });
                                  navigator.clipboard.writeText(ShareUrl);
                                  setCopied(true);
                                }}
                                className="border cursor-pointer px-3 flex items-center glass-background   mt-2 h-11 rounded-xl">
                                <input
                                  className="px-3 bg-transparent w-full text-xl font-light "
                                  value={`https://www.whatstba.com/e/${event?.slug}`}
                                />
                                <div
                                  data-tooltip-content={copied ? 'Copied' : 'Copy'}
                                  data-tooltip-id="tooltip-copy"
                                  className="ml-auto   font-light cursor-pointer">
                                  <CopyIcon />
                                  <Tooltip
                                    id="tooltip-copy"
                                    render={({ content }) => <p className=" text-base h-fit ">{content}</p>}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </strong>
                  </div>
                </FadeIn>
              </div>
            )}
            {step === CheckoutFlow.ERROR && (
              <div className="w-100 dib">
                <FadeIn>
                  <div className="pv4 text-gray-01 mb4 f3-ns md:px-0 px-3 f4 tc lh-title">
                    <p className="mv1 text-gray-01 fw6 f1-ns  f2">Error during checkout</p>
                    <p className="w-70 center text-center">
                      Sorry, there was an error processing your payment. Please refresh and try again or contact support
                      for assistance.
                    </p>
                    <div className="tc mt2 pt2 flex w-[240] mx-auto items-center justify-center md:inline-flex md:flex-row flex-col md:pt-5 pt-9 md:gap-[20px]">
                      <button
                        onClick={() => {
                          trackAction({
                            action: AMPLITUDE_ACTIONS.CLICK,
                            event: AMPLITUDE_EVENTS.CHECKOUT_TRY_AGAIN,
                            opts: {
                              orderId: order?._id,
                              orderEventId: order?.eventId,
                              orderTicketName: order?.ticketName,
                              orderFirstName: order?.firstName,
                              orderLastName: order?.lastName,
                              orderEmailAddress: order?.emailAddress,
                              orderTotal: order?.total,
                            },
                            eventData: {
                              eventId: event?._id,
                              eventName: event?.name,
                            },
                          });
                          return router.reload();
                        }}
                        className="b--white transition-all hover:text-black hover:bg-white noselect ba bw2 md:mr-2 md:mb-0 br-100 b--solid pa2 ph4 f4 fw5">
                        Try again
                      </button>
                      <a
                        onClick={() => {
                          trackAction({
                            action: AMPLITUDE_ACTIONS.CLICK,
                            event: AMPLITUDE_EVENTS.SUPPORT_CONTACT,
                            opts: {
                              orderId: order?._id,
                              orderEventId: order?.eventId,
                              orderTicketName: order?.ticketName,
                              orderFirstName: order?.firstName,
                              orderLastName: order?.lastName,
                              orderEmailAddress: order?.emailAddress,
                              orderTotal: order?.total,
                            },
                            eventData: {
                              eventId: event?._id,
                              eventName: event?.name,
                            },
                          });
                          window.location.href = 'mailto:info@whatstba.com';
                        }}
                        className="b--white mt-[30px] md:mt-0 transition-all hover:text-black hover:bg-white noselect ba bw2 br-100 b--solid pa2 ph4 f4 fw5">
                        Support
                      </a>
                    </div>
                  </div>
                </FadeIn>
              </div>
            )}
            {step === CheckoutFlow.PROCESSING && (
              <div className="w-100  dib">
                <FadeIn>
                  <div className="pb4 dt center">
                    <div className="v-mid dtc">
                      <Lottie options={defaultOptions} height={400} width={'auto'} />
                    </div>
                  </div>
                </FadeIn>
              </div>
            )}
            {step === CheckoutFlow.USER_CHECKOUT && (
              <Elements stripe={stripePromise}>
                <div className="w-full bg-tbaBlue py-0 ">
                  <div className=" bg-tbaBlue mx-auto text-black  md:px-10 px-8 rounded-xl">
                    <FadeIn>
                      <UserCheckoutForm
                        extraFields={event.extraFields || []}
                        currency={event.currency}
                        eventCountry={event.location.country as Country}
                        scroll={executeScroll}
                        clientSecret={clientSecret}
                        event={event}
                        waitlist={waitlist}
                        handleCheckout={handleCheckout}
                        autoAccept={autoAccept}
                        firstName={firstName}
                        lastName={lastName}
                        setZipCode={setZipCode}
                        zipCode={zipCode}
                        getApplePayOrder={getApplePayOrder}
                        setLastName={setLastName}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        setFirstName={setFirstName}
                        emailAddress={emailAddress}
                        setEmailAddress={setEmailAddress}
                        setMode={setMode}
                        offerBased={offerBased}
                        total={total}
                        utm_medium={utm_medium}
                        utm_source={utm_source}
                      />
                    </FadeIn>
                  </div>
                </div>
              </Elements>
            )}
            <div>
              {step === CheckoutFlow.TICKET_SELECTION && (
                <div className="w-full py-0  ">
                  <Elements stripe={stripePromise}>
                    <FadeIn>
                      <div className="bg-tbaBlue  mx-auto md:ml-10 text-black sm:rounded-md">
                        <TicketCheckoutForm
                          event={event}
                          cart={cart}
                          handleCheckout={handleCheckout}
                          clientSecret={clientSecret}
                          scroll={executeScroll}
                          setWaitlist={setWaitlist}
                          order={order}
                          getApplePayOrder={getApplePayOrder}
                          setCart={setCart}
                          ticketTypes={event.ticketTypes}
                          setMode={setMode}
                          absorb={event.absorb}
                          currency={event.currency}
                          setTotal={setTotal}
                          setOfferBased={setOfferBased}
                          setAutoAccept={setAutoAccept}
                          offers={offers}
                          setOffers={setOffers}
                          setOfferCode={setOfferCode}
                          utm_medium={utm_medium}
                          utm_source={utm_source}
                        />
                      </div>
                    </FadeIn>
                  </Elements>
                </div>
              )}
            </div>
          </div>
        )}

        <article className="cf w-100 pa4-ns pa3 ">
          {hasDescription() && (
            <div
              className={`fl ${classNames({
                'md:w-2/4': hasLineUp,
                'w-full': !(hasLineUp || hasPhotos),
              })} w-100 tl mb4`}>
              <span className="f3-l f4 fw6-l fw4 br-100 b--solid pv2 ph3 bw2">Description</span>

              <div className="mt4 ">
                {event.organizerName && (
                  <div className="flex justify-start items-start  ">
                    <i className="gray fas fa-bomb text-center w-8" />
                    <span className="text-xs font-semibold gray font-grotesk-md">
                      {`Presented by ${event.organizerName}`}
                    </span>
                  </div>
                )}
                {/* <div className="flex justify-start items-start mt1 ">
								<i className="gray pt2 fas fa-exclamation  text-center w-8" />
								<div className="mt1 text-xs font-semibold gray font-grotesk-md">
									<span className="block">{`Covid-19 entry policy`}</span>
									<span className="block">{`Vaccination required or 72-hour test`}</span>
								</div>
							</div> */}
              </div>
              <ParentDiv
                className={`pt2-ns event-description ${classnames({
                  mt2: event.organizerName,
                  mt4: !event.organizerName,
                })} pt1 pr2 f4-ns f5 lh-title `}
                dangerouslySetInnerHTML={{
                  __html: event.description,
                }}
              />

              {/* {showReadMore && (
              <span
                onClick={() => (readMore ? setReadMore(false) : setReadMore(true))}
                className="f4-ns f5 fw6 noselect">
                {readMore ? `Read less` : `Read more`}
              </span>
            )} */}
            </div>
          )}
          {hasLineUp > 0 && (
            <div
              className={`fr ${classNames({
                'md:w-2/4': hasLineUp,
              })} w-100 ml3-md mb4 tl`}>
              <span className="f3-l f4 fw6-l fw4 br-100 b--solid pv2 ph3 bw2">Line Up</span>{' '}
              <div className="pt2-ns mt4 pt1">
                {/* <Grid artists={hasLineUp}> */}
                <ul>
                  {Object.values(event.lineUp)
                    // .sort((artistA, artistB) => (artistA.artistName > artistB.artistName ? 1 : -1))
                    .map((curr) => {
                      const { imageURL, artistName, igHandle, spotifyUrl, previewUrl, soundcloudUrl } = curr;
                      const igLink =
                        igHandle.length > 1
                          ? `https://instagram.com/${igHandle[0] === '@' ? igHandle.slice(1) : igHandle}`
                          : ``;
                      const squaredImage = imageURL.split('upload').join('upload/c_fill,g_face,h_400,w_400');
                      return (
                        <li key={imageURL} className="w-100 mb3">
                          <div className="flex">
                            {squaredImage && (
                              <div className=" w-20 h-20 ">
                                <a href={igLink} target="_blank">
                                  <img className="rounded-full " src={squaredImage} alt="" />
                                </a>
                              </div>
                            )}
                            <div className="flex items-start justify-between">
                              <div className="pl-6 w-full">
                                <p className="pt-4 f4">
                                  <a className="no-underline dim white" href={igLink} target="_blank">
                                    <strong>{artistName}</strong>
                                  </a>
                                </p>
                                <div className="pt-2 f4">
                                  <div className="inline-block text-base">
                                    {/* {previewUrl.length > 0 && (
																	<i
																		onClick={() => {
																			setTrackUrl(previewUrl);
																			setPlaying(!playing);
																		}}
																		className={`${classNames({
																			'fa-play':
																				!playing || trackUrl !== previewUrl,
																			'fa-pause':
																				playing && trackUrl === previewUrl,
																		})} text-sm fas pr-2 mb-1`}
																	/>
																)} */}
                                  </div>
                                  {igLink.length > 0 && (
                                    <a className="no-underline dim white" href={igLink} target="_blank">
                                      <i className="fab fa-instagram" />
                                    </a>
                                  )}
                                  {spotifyUrl?.length > 0 && (
                                    <a className="no-underline dim white" href={spotifyUrl} target="_blank">
                                      <i className="pl-2 fab fa-spotify" />
                                    </a>
                                  )}
                                  {soundcloudUrl?.length > 0 && (
                                    <a className="no-underline dim white" href={soundcloudUrl} target="_blank">
                                      <i className="pl-2 fab fa-soundcloud" />
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>
                            <br />
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          )}
          {!areAllTicketsFree(event) && (
            <>
              <span className="f3-l f4 fw6-l fw4 br-100 b--solid pv2 ph3 bw2">Refund Policy</span>
              <div className="pt2-ns mt2 pt-6 pr-1 f4-ns f5 lh-title ">
                <p>
                  {hasRefundPolicy
                    ? 'Refunds are available up to three days before the event.'
                    : `All sales are final${
                        event.transferable ? ` and non-transferable.` : `.`
                      } No refunds will be available for this event.`}
                </p>
              </div>
            </>
          )}
        </article>
      </div>
      {hasPhotos > 0 && (
        <div className="cf w-100 pa4-ns pa3 ">
          <span className="f3-l f4 fw6-l fw4 br-100 b--solid pv2 ph3 bw2">Photos</span>
          <div className="flex sm:gap-6 gap-4 flex-wrap mt-10 relative justify-center sm:justify-start">
            {event.photos.map((curr) => {
              if (curr.width) {
                return (
                  <div
                    key={curr.url}
                    className="lg:h-[600px] relative lg:w-[600px] md:h-[450px] md:w-[450px] h-[300px] w-[300px]">
                    <Image
                      layout="fill"
                      className="db  pb2  mx-auto"
                      style={{ objectFit: 'contain' }}
                      objectFit="cover"
                      src={curr.url}
                      alt=""
                    />
                  </div>
                );
              }
              return (
                <div key={curr} className="w-100 mb3">
                  <img className="db w-100 pb2  mx-auto" src={curr} alt="" />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </main>
  );
};
