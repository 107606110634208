import classNames from 'classnames';
import { ReactNode } from 'react';

type PropTypes = {
  onClick?: any;
  children?: ReactNode;
  active?: boolean;
  className?: any;
};

const Tags: React.FC<PropTypes> = ({ children, onClick, active, className }) => {
  return (
    <div onClick={onClick} className={className}>
      <div className="block">{children}</div>
    </div>
  );
};

export default Tags;
