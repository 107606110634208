import * as React from 'react';

import { createLogger } from '@/modules/logging/logger';
import { Layout } from './Layout';

export const Spinner: React.FunctionComponent = ({}) => {
	return (
		<Layout>
			<div className="vh-100 dt center">
				<div className="v-mid dtc">
					<i className="fa-6x fa fa-spinner fa-spin mr2" />
				</div>
			</div>
		</Layout>
	);
};
