import classNames from 'classnames';
import Cleave from 'cleave.js/react';
import React, { ReactNode, useState } from 'react';
type Props = {
  id: string;
  name: string;
  error?: boolean;
  onBlur?: any;
  onClick?: any;
  onChange?: any;
  onWheel?: any;
  type?: string;
  onKeyDown?: any;
  placeholder?: string;
  errorMessage?: string;
  required?: boolean;
  prefix?: string | boolean;
  suffix?: string | boolean;
  inputMode?: 'search' | 'text' | 'none' | 'email' | 'tel' | 'url' | 'numeric' | 'decimal';
};

type RadioGroupInputProps = {
  children?: ReactNode;
  defaultValue?: number;
};
type RadioInputProps = {
  id?: string;
  active?: boolean;
  setIsActive?: any;
  onClick: any;
  children?: ReactNode;
  index?: number;
  nameRadio?: string;
};
type CheckBoxInputProps = {
  children?: ReactNode;
  defaultValue?: boolean;
  reverseLayout?: boolean;
  onClick: any;
  name?: string;
  id?: string;
  disabled?: boolean;
};
type SwitchInputProps = {
  id?: string;
  checked: boolean;
  label: string;
  disabled?: boolean;
  value?: string;
  onChange?: any;
};
export const BasicInput = ({
  id,
  name,
  error,
  errorMessage,
  onChange,
  onBlur,
  placeholder,
  type,
  onWheel,
  required,
  inputMode,
  onKeyDown,
}: Props) => {
  return (
    <div>
      <div
        className={`mt3 mb2 floating-input relative tl bg-black  ${classNames({
          'outline  outline-white ': !error,
          'ba-hover-error ': error,
        })}`}>
        <input
          id={id}
          value={name}
          onChange={onChange}
          onBlur={onBlur}
          type={type}
          onKeyDown={onKeyDown}
          onWheel={onWheel}
          inputMode={inputMode}
          placeholder={placeholder}
          className=" bg-red ml-1 py-2  mt-[6px] h-10 text-white pl-2  placeholder:text-transparent  input-reset  bn w-90 bg-transparent"
        />

        <label className=" pointer-events-none absolute top-0 left-0 mb-2 flex  h-full origin-left transform flex-nowrap whitespace-nowrap px-3  py-2 leading-[195%] opacity-70 transition-all duration-100 ease-in-out sm:leading-['normal']">
          {placeholder}
        </label>
        {required && <span className="red ml-2">*</span>}
      </div>

      {error && <small className="db tl red">{errorMessage}</small>}
    </div>
  );
};

export const CleaveInput = ({
  id,
  error,
  errorMessage,
  onChange,
  onBlur,
  name,
  onWheel,
  required,
  placeholder,
  type = 'number',
  inputMode = 'numeric',
  prefix = false,
  suffix = false,
}: Props) => {
  return (
    <div>
      <div
        className={`mt3 mb2  floating-input relative tl bg-black  ${classNames({
          'outline  outline-white ': !error,
          'ba-hover-error ': error,
        })}`}>
        <Cleave
          id={id}
          style={{ boxSizing: 'initial' }}
          value={name}
          onChange={onChange}
          onBlur={onBlur}
          options={{}}
          className={`  pl3 mt-[6px] h-10 bn placeholder:pl-2 input-reset ml1 placeholder:text-transparent  w-90 bg-transparent`}
          type={type}
          placeholder="Price"
          inputMode={inputMode}
        />
        {suffix && (
          <span style={{ minWidth: '40px' }} className="absolute top-1 right-0 my-[10px] pl2 tl">
            {suffix}
          </span>
        )}
        {prefix && (
          <span style={{ minWidth: '40px' }} className="absolute top-[0.9px] left-0 my-[10px] pt-[2.5px] pl2 tl">
            {prefix}
          </span>
        )}
        <label
          className={` pointer-events-none absolute ${
            prefix ? 'left-2' : 'left-0'
          } top-0  mb-2 flex  h-full origin-left transform flex-nowrap whitespace-nowrap px-3  py-2 leading-[195%] opacity-70 transition-all duration-100 ease-in-out sm:leading-['normal']`}>
          {placeholder}
        </label>
        {required && <span className="red my-auto mr-1 ml-2">*</span>}
      </div>

      {error && <small className="db tl red">{errorMessage}</small>}
    </div>
  );
};
export const TextArea = ({
  id,
  name,
  error,
  errorMessage,
  onChange,
  onBlur,
  placeholder,
  onWheel,
  required = false,
  inputMode,
}: Props) => {
  return (
    <div>
      <div
        className={`mt3 mb2 border  floating-input  focus:outline-0 relative tl bg-black  ${classNames({
          'outline-white floating-input ': !error,
          'ba-hover-error floating-input ': error,
        })}`}>
        <textarea
          id={id}
          value={name}
          onChange={onChange}
          onBlur={onBlur}
          onWheel={onWheel}
          inputMode={inputMode}
          placeholder={placeholder}
          className="pl2 border focus:outline-none  mt-[6px]   h-20 pt-2 bn input-reset bg-transparent  mr3  w-100  placeholder:text-transparent"
        />
        <label className=" pointer-events-none absolute top-0 left-0 mb-2 flex  h-full origin-left transform flex-nowrap whitespace-nowrap px-3  py-2 leading-[195%] opacity-70 transition-all duration-100 ease-in-out sm:leading-['normal']">
          {placeholder}
        </label>
        {required && <span className="red ml-2">*</span>}
      </div>

      {error && <small className="db tl red">{errorMessage}</small>}
    </div>
  );
};
export const RadioInput = ({ children, active, setIsActive, index, onClick, nameRadio }: RadioInputProps) => {
  const onRadioClicked = () => {
    try {
      onClick();
      setIsActive(index);
    } catch {
      setIsActive(-1);
    }
  };
  return (
    <div
      onClick={onRadioClicked}
      className="outline flex cursor-pointer sm:px-3 justify-center   items-center w-full h-12">
      <div
        id={`radio-${nameRadio}-${index}`}
        className="w-[15px] flex h-[15px] sm:mx-0 ml-[15px] mr-[5px]  rounded-full items-center justify-center bg-white">
        {active && <div className="w-[10px] rounded-full  h-[10px] bg-black"></div>}
      </div>
      <p className={`mr-auto sm:px-2 px-[0.5px] text-sm ${active ? 'opacity-100 font-semibold' : ' opacity-70'}`}>
        {' '}
        {children}
      </p>
    </div>
  );
};
export const RadioGroupInput = ({ children, defaultValue = -1 }: RadioGroupInputProps) => {
  const [isActive, setIsActive] = useState(defaultValue);

  return (
    <div className=" flex w-full gap-x-3">
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child as any, {
          active: index === isActive,
          setIsActive: setIsActive,
        }),
      )}
    </div>
  );
};
export const Switch = ({ id = '', checked, onChange, label, disabled = false, value }: SwitchInputProps) => {
  return (
    <div className={` ${disabled ? 'cursor-default opacity-50' : 'cursor-pointer'} items-center flex w-fit`}>
      <label
        className={`flex cursor-pointer h-fit w-[40px] items-center rounded-full transition-colors duration-300 ease-in-out focus:outline-none ${
          checked ? 'bg-tbaGreen' : 'bg-gray-400'
        }`}>
        <div className="relative  ">
          <input
            value={value}
            type="checkbox"
            className="hidden"
            onChange={disabled ? null : onChange}
            checked={checked}
          />
          <span className={` w-8 h-6 `} />
          <span
            id={id}
            className={`${
              checked ? 'translate-x-5' : 'translate-x-1'
            } inline-block w-4 h-4 mt-[3px] rounded-full bg-gray-100 shadow transform transition-transform duration-300 ease-in-out`}
          />
        </div>
      </label>
      <span className=" ml-3 gray ">{label}</span>
    </div>
  );
};

export const CheckBoxInput = ({
  onClick,
  children,
  reverseLayout,
  defaultValue,
  disabled = false,
  id,
  name,
}: CheckBoxInputProps) => {
  const [isChecked, setIsChecked] = useState(defaultValue);

  const handleCheckboxChange = (e) => {
    if (!disabled) {
      onClick(e);
      setIsChecked(!isChecked);
    }
  };

  return (
    <div id={id} className="select-none  cursor-pointer relative flex items-center">
      <input
        name={name}
        type="checkbox"
        className=" opacity-0 cursor-pointer w-full absolute  "
        onChange={handleCheckboxChange}
        checked={isChecked}
      />
      <span className={`mr-2  ${reverseLayout ? 'hidden' : 'block'}`}>{children}</span>
      <label className="inline-block cursor-pointer">
        <div
          className={` ${
            disabled ? 'bg-gray-400' : 'bg-white'
          } p-[1.8px] rounded-sm w-4 h-4 flex flex-shrink-0 justify-center items-center focus-within:border-blue-500`}>
          {isChecked && (
            <svg className="fill-current w-4 h-4  text-black pointer-events-none" viewBox="0 0 20 20">
              <path
                d="M0 11l2-2 5 5L18 3l2 2L7 18z"
                strokeWidth="10" // Update the stroke width to make it thicker
                strokeLinecap="round"
              />
            </svg>
          )}
        </div>
      </label>
      <span className={`ml-2  ${reverseLayout ? 'block' : 'hidden'}`}>{children}</span>
    </div>
  );
};
