import React, { useEffect } from 'react';
import { calculateFees, formatPrice, isOfferBased } from '@/lib/index';
import { useState } from 'react';
import { EventCartProps } from '@/modules/data/types/EventCart';
import { TicketProps } from '@/modules/data/types/Ticket';
import { createLogger } from '@/modules/logging/logger';
import moment from 'moment-timezone';
import CurrencyInput from 'react-currency-input-field';
import { AMPLITUDE_ACTIONS, AMPLITUDE_EVENTS } from '@/modules/amplitude/events';
import { trackAction } from '@/modules/amplitude/amplitudeBrowserClient';
import { EventProps } from '@/modules/data/types/';

const logger = createLogger({
  fileLabel: '/src/components/Event/TicketSelection',
});

interface EventTicketSelectionProps {
  id: number;
  guestList?: boolean;
  joinWaitlist?: () => void;
  updateTixPrice?: (ticket: TicketProps) => void;
  updateAddCart: (ticket: TicketProps, quantity: number) => void;
  updateRemoveCart: (ticket: TicketProps, quantity: number) => void;
  cart: {
    [ticketName: string]: EventCartProps;
  };
  currency: string;
  ticketType: TicketProps;
  event: EventProps;
}

export const TicketSelection: React.FunctionComponent<EventTicketSelectionProps> = ({
  id,
  ticketType,
  updateAddCart,
  updateRemoveCart,
  guestList,
  joinWaitlist,
  currency,
  updateTixPrice,
  cart,
  event,
}) => {
  const [offerAmt, setOfferAmt] = useState<number>(isOfferBased(ticketType) && guestList ? 0 : null);
  const [quantity, setQuantity] = useState<number>(
    Object.keys(cart).reduce((acc, curr) => (cart[curr].ticketName === ticketType.ticketName ? acc + 1 : acc), 0),
  );

  const freeTix = ticketType.price > 0 ? false : true;
  const soldOut = quantity + 1 > ticketType.quantity - ticketType.sold;
  const disabled = !ticketType.enabled;
  const expired = !ticketType.ticketEndDate ? false : moment(new Date(ticketType.ticketEndDate)).isBefore(new Date());

  // const feeString = formatPrice(parseFloat(ticketType.fee.toString()).toString());
  useEffect(() => {
    if (!Object.keys(cart).length) {
      setQuantity(0);
    }
  }, [cart]);
  const handleDeductTicket = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
      updateRemoveCart(ticketType, quantity - 1);
    }
  };
  const handleIncreaseTicket = () => {
    if (guestList || (!soldOut && quantity < ticketType.max)) {
      setQuantity(quantity + 1);
      updateAddCart({ ...ticketType, price: offerAmt ?? ticketType.price }, quantity + 1);
    }
  };
  function renderTicketIcons() {
    const isSoldOut = soldOut || disabled || expired;

    if (isSoldOut && !guestList) {
      return (
        <button id={`soldout-${ticketType._id}`} className="red fw7-ns fw5 flex text-sm " onClick={joinWaitlist}>
          Sold Out!
        </button>
      );
    }
    // useEffect(() => {
    //   console.log('Quantity:' + quantity);
    //   console.log('TicketsSold:' + ticketType.sold);
    //   console.log('TicketQuantity:' + ticketType.quantity);
    //   console.log('SOLD OUT:' + soldOut);
    // }, [quantity]);
    return (
      <div className="mx-auto mb-3 mt-3 flex h-full w-fit items-center md:ml-auto  md:mt-0 md:mb-0">
        <div className=" flex h-[26px] items-center justify-center rounded-full bg-black p-[4px] px-[5px]">
          <i
            id={`decrease-${ticketType._id || id}`}
            onClick={() => {
              trackAction({
                action: AMPLITUDE_ACTIONS.CLICK,
                event: AMPLITUDE_EVENTS.TICKET_QUANTITY_DECREASE,
                opts: {
                  ticketName: ticketType.ticketName,
                  ticketId: ticketType._id,
                  ticketPrice: ticketType.price,
                  ticketQuantity: quantity,
                  ticketMax: ticketType.max,
                },
                eventData: {
                  eventId: event?._id,
                  eventName: event?.name,
                },
              });
              handleDeductTicket();
            }}
            className="fa fa-minus fa-sm  cursor-pointer rounded-full   text-gray-01"
          />
        </div>
        <div className="mx-auto flex w-fit  ">
          <input
            id={`quantity-${ticketType._id || id}`}
            type="number"
            value={quantity}
            className="remove-counter f4 fw7-ns fw5 mx-1 w-6   bg-transparent text-center text-gray-01 "
            // onChange={handleTicketInput}
          />
        </div>
        <span
          className={`${
            ticketType.max == quantity ? 'cursor-default' : 'cursor-pointer'
          } flex h-[26px] items-center justify-center rounded-full bg-black p-[4px] px-[5px]`}
          style={{ opacity: ticketType.max == quantity && '0.4' }}>
          <i
            id={`increase-${ticketType._id || id}`}
            onClick={() => {
              trackAction({
                action: AMPLITUDE_ACTIONS.CLICK,
                event: AMPLITUDE_EVENTS.TICKET_QUANTITY_INCREASE,
                opts: {
                  ticketName: ticketType.ticketName,
                  ticketId: ticketType._id,
                  ticketPrice: ticketType.price,
                  ticketQuantity: quantity,
                  ticketMax: ticketType.max,
                },
                eventData: {
                  eventId: event?._id,
                  eventName: event?.name,
                },
              });
              handleIncreaseTicket();
            }}
            className={`fa fa-plus fa-sm  cursor-pointer rounded-full   text-gray-01`}
          />
        </span>
      </div>
    );
  }
  function renderOfferBasedTicket() {
    if (!isOfferBased(ticketType)) {
      return null;
    }

    const isSoldOut = soldOut || disabled || expired;

    if (isSoldOut && !guestList) {
      return (
        <div className="w-full items-center justify-end text-xl ">
          <span id={`soldout-${ticketType._id}`} className="red fw7-ns fw5 flex text-sm mt-2 sm:mt-0">
            Sold Out!
          </span>
        </div>
      );
    }

    const isOfferAmtValid =
      offerAmt !== null &&
      Number.isInteger(offerAmt) &&
      (ticketType.minimum ? offerAmt >= ticketType.minimum : offerAmt >= 0);

    return (
      <div className="w-full  text-xl">
        <div className="flex flex-col-reverse  md:flex-row-reverse justify-center items-center">
          <div>
            {(guestList || !!isOfferAmtValid) && (
              <div className="h-[44px]  w-full items-center justify-center text-xl ">
                <div className="mx-auto mb-3 mt-3 flex h-full w-fit items-center md:ml-auto  md:mt-0 md:mb-0">
                  <div className=" flex h-[26px] items-center justify-center rounded-full bg-black p-[4px] px-[5px]">
                    <i
                      id={'decrease-' + id}
                      onClick={() => {
                        trackAction({
                          action: AMPLITUDE_ACTIONS.CLICK,
                          event: AMPLITUDE_EVENTS.TICKET_QUANTITY_DECREASE,
                          opts: {
                            ticketName: ticketType.ticketName,
                            ticketId: ticketType._id,
                            ticketPrice: ticketType.price,
                            ticketQuantity: quantity,
                            ticketMax: ticketType.max,
                          },
                          eventData: {
                            eventId: event?._id,
                            eventName: event?.name,
                          },
                        });
                        handleDeductTicket();
                      }}
                      className="fa fa-minus fa-sm  cursor-pointer rounded-full   text-gray-01"
                    />
                  </div>
                  <div className="mx-auto flex w-fit  ">
                    <input
                      type="number"
                      value={quantity}
                      className="remove-counter f4 fw7-ns fw5 mx-1 w-6   bg-transparent text-center text-gray-01 "
                      readOnly={ticketType.ticketType !== 'offer'}
                    />
                  </div>
                  <span
                    className={`${
                      ticketType.max == quantity ? 'cursor-default' : 'cursor-pointer'
                    } flex h-[26px] items-center justify-center rounded-full bg-black p-[4px] px-[5px]`}
                    style={{ opacity: ticketType.max == quantity && '0.4' }}>
                    <i
                      id={`increase-${ticketType._id || id}`}
                      onClick={() => {
                        trackAction({
                          action: AMPLITUDE_ACTIONS.CLICK,
                          event: AMPLITUDE_EVENTS.TICKET_QUANTITY_INCREASE,
                          opts: {
                            ticketName: ticketType.ticketName,
                            ticketId: ticketType._id,
                            ticketPrice: ticketType.price,
                            ticketQuantity: quantity,
                            ticketMax: ticketType.max,
                          },
                          eventData: {
                            eventId: event?._id,
                            eventName: event?.name,
                          },
                        });
                        handleIncreaseTicket();
                      }}
                      className={`fa fa-plus fa-sm  cursor-pointer rounded-full   text-gray-01`}
                    />
                  </span>
                </div>
              </div>
            )}
          </div>

          {!soldOut && !disabled && !expired && !guestList && (
            <CurrencyInput
              id={`input-user-suggested-price-${ticketType._id || id}`}
              name="input-name"
              prefix="$"
              placeholder={`WHAT IS IT WORTH TO YOU ?`}
              className={`max-w-[423px] ${
                guestList
                  ? 'black w-50'
                  : 'w-75 placeholder:opacity-3 glass-background mx-auto mt-1 h-12 rounded-full  text-center text-gray-01 placeholder:text-base  md:ml-0'
              } `}
              defaultValue={offerAmt}
              decimalsLimit={2}
              onValueChange={(value) => {
                setOfferAmt(parseInt(value));
                updateTixPrice({
                  ...ticketType,
                  price: parseInt(value),
                  fee: calculateFees(value, true, currency, event.organizerId?.[0]),
                });
              }}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className=" pt-4">
      <div className=" fw7 flex flex-col md:flex-row md:px-10  ">
        <div className="mr-[30px] flex w-full items-center  text-xl md:w-[22%] md:text-left">
          <div className="mx-auto mb-1 flex w-fit flex-col md:ml-0  md:w-[300px]" id={`ticket-${ticketType._id || id}`}>
            <p className="mb-[0.2px] text-lg text-gray-01 md:text-xl">{ticketType.ticketName}</p>
          </div>
        </div>

        {isOfferBased(ticketType) && renderOfferBasedTicket()}
      </div>
      <div
        className={`ml-auto mb-2 flex w-full flex-col  items-center md:flex-row  md:px-10 ${
          !isOfferBased ? 'justify-end' : 'justify-between'
        } `}>
        <div>
          {!isOfferBased(ticketType) && (
            <div className="w-full text-lg font-semibold text-gray-01 md:w-fit">
              {`${formatPrice(ticketType?.price?.toString() || '0', false, currency)}`}
              {!freeTix}
            </div>
          )}
          {event.slug === 'mannywellz.acoustic.game.night'
            ? !((soldOut || disabled || expired) && !guestList) && (
                <p className="text-sm font-medium text-gray-01">{ticketType.quantity - ticketType.sold} Tickets left</p>
              )
            : null}
        </div>

        <div>{!isOfferBased(ticketType) && <div className="w-full  text-xl ">{renderTicketIcons()}</div>}</div>
      </div>
      {!guestList && (
        <>
          <p className="ma fw4 red mt-5  max-w-4xl px-10 pb-3">
            {ticketType?.minimum && Number.isInteger(offerAmt)
              ? offerAmt < ticketType.minimum &&
                `Please enter an amount more than ${formatPrice(ticketType.minimum.toString(), true, currency)}`
              : ``}
          </p>
          <p className="ma fw4 max-w-4xl  px-5 pb-3 text-gray-01 md:px-10">{ticketType?.description}</p>
        </>
      )}
      <div className="mt-2 h-[1px] bg-white opacity-25"></div>
    </div>
  );
};
