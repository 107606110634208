// ./utils/get-stripejs.ts
import { stripeClient } from '@/lib/index';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import StripeLib from 'stripe';

let stripePromise: Promise<Stripe | null>;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(stripeClient);
  }
  return stripePromise;
};

type customerCreationStripe = {
  email: string;
  name: string;
  phoneNumber: string;
}

type cardCreationStripe = {
  customerId: string,
  cardToken: StripeLib.Token
}

export class StripeModule {
  #stripe: StripeLib;

  constructor(stripeSecret: string) {
    this.#stripe = new StripeLib(stripeSecret, { apiVersion: '2022-11-15' });
  }

  /**
   * Create a customer in Stripe. If customer already exists, return it.
   * 
   * @param {customerCreationStripe} customerData 
   * @returns {Promise<StripeLib.Customer>}
   */
  createCustomerStripe = async (customerData: customerCreationStripe): Promise<StripeLib.Customer> => {

    const customer = await this.findCustomerStripe(customerData.email);
    
    if(customer) return customer;

    const customerNew = await this.#stripe.customers.create({
      email: customerData.email,
      name: customerData.name,
      phone: customerData.phoneNumber,
    });

    return customerNew;
  }
  
  /**
   * Return one customer in Stripe by email. Return null if not found.
   * 
   * @param {string} email 
   * @returns {Promise<StripeLib.Customer>}
   */
  findCustomerStripe = async (email: string): Promise<StripeLib.Customer> => {
    const customers = await this.#stripe.customers.search({ query: `email:'${email}'`, limit: 1 });
    const customer = customers.data.length > 0 ? customers.data[0] : null;
    
    return customer;
  }

  /**
   * Create a card for a customer in Stripe.
   * 
   * @param {cardCreationStripe} cardData 
   * @returns {Promise<StripeLib.CustomerSource>}
   */
  createCardStripe = async (cardData: cardCreationStripe): Promise<StripeLib.CustomerSource> => {
    const card = await this.#stripe.customers.createSource(cardData.customerId, {
      source: cardData.cardToken.id,
    });

    return card;
  }

  /**
   * Return all cards for a customer in Stripe.
   * 
   * @param {string} customerId
   * @returns {Promise<StripeLib.Response<StripeLib.ApiList<StripeLib.CustomerSource>>>}
   */
  findCardStripe = async (customerId: string): Promise<StripeLib.Response<StripeLib.ApiList<StripeLib.CustomerSource>>> => {
    const cards = await this.#stripe.customers.listSources(customerId, { object: 'card' });
    
    return cards;
  }

}


export default getStripe;
