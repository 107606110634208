import React from 'react';
import Modal from 'react-modal';
import { createLogger } from '@/modules/logging/logger';
import { purple } from '@/lib/index';
import { trackAction } from '@/modules/amplitude/amplitudeBrowserClient';
import { AMPLITUDE_ACTIONS, AMPLITUDE_EVENTS } from '@/modules/amplitude/events';
import { IconX } from '@tabler/icons-react';
const logger = createLogger({
  fileLabel: `components/Dashboard/ManageEvent/ManageEvent/PayoutModal`,
});
interface TermsModalProps {
  afterOpenModal?: () => void;
  closeModal?: () => void;
  isOpen?: boolean;
}

const customStyles = {
  overlay: {
    position: 'fixed',
    top: '10%',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    overflowY: 'auto',
  },
};

export const TermsModal: React.FunctionComponent<TermsModalProps> = ({ isOpen, closeModal, afterOpenModal }) => {
  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      style={customStyles}
      className="absolute inset-0 left-1/2 w-[90%] md:w-1/2 h-full bg-white text-black border border-gray-300 overflow-auto rounded p-5 -translate-x-1/2">
      <main style={{ color: purple, position: 'relative' }}>
        <div id="terms" className="font-brown-reg">
          <>
            <h1 className="f1-ns f2">TBA</h1>
            <p className="mv2 f4">Ticketing Terms and Conditions</p>
            <p className="mv2 leading-normal">
              TBA (the “Site”) is an event ticketing platform owned by WhatsTBA Inc. (“TBA”). By purchasing tickets
              through the Event ticketing website (www.whatstba.com), you, the ticket purchaser, agree to comply with
              these Ticketing Terms and Conditions and the Organizer’s Terms of Use (available at{' '}
              <a className="font-bold" href="https://whatstba.com/terms">
                https://whatstba.com/terms
              </a>
              ), and acknowledge that you have read the Organizer’s Privacy Policy (available at{' '}
              <a className="font-bold" href="https://whatstba.com/privacy">
                https://whatstba.com/privacy
              </a>
              ).
            </p>
            <p className="mv2 leading-normal">
              TBA provides a platform for event organizers to sell tickets to their events and for fans to purchase
              these tickets. TBA acts solely as an intermediary between the organizer and the ticket purchaser. TBA is
              not responsible for the organization, quality, or safety of the events themselves.
            </p>
            <p className="mv2 leading-normal">
              {' '}
              The Event ticketing website is powered by WhatsTBA Inc. (“TBA”). Tickets purchased through TBA are only
              valid for the date and session indicated. Each ticket is valid for a single-person entry and will be
              scanned upon entry. It is the ticket holder’s responsibility to ensure the accuracy of the ticket details
              at the time of purchase. TBA is not liable for any errors or omissions. Tickets may not be resold,
              exchanged, or returned. Altering or defacing any ticket will render it void.
            </p>
            <p className="mv2 leading-normal">
              The event organizer reserves the right to refuse admission to any ticket holder and may conduct security
              searches for the safety of all attendees. The organizer may remove anyone from the event who is violating
              laws, event rules, or venue policies, or is impaired by drugs or alcohol.
            </p>
            <p className="mv2 leading-normal">
              By purchasing a ticket, you agree to receive email and SMS notifications related to the event, including
              ticketing information and updates. You may opt-out of these communications at any time by following the
              unsubscribe instructions provided. Your contact information will be shared with the event organizer for
              event-related communications and may be used in accordance with their privacy policy.
            </p>
            <p className="mv2 leading-normal">
              The Organizer reserves the right to alter or vary the content or timing of the whole or any part of the
              Event due to circumstances beyond their reasonable control without being obliged to refund or exchange
              tickets. Tickets are sold subject to Organizer’s absolute right to alter or vary the Event without any
              obligation to make a refund. If the Event is cancelled in its entirety prior to its commencement, then
              ticket holders (of all ticket types) may be entitled to a refund of the ticket price but no refund will be
              given in respect of any other expenditure whatsoever. Details of how to claim refunds, exchanges or future
              discounts will be provided by the Organizer on the day of the Event and/or on the Event website.
            </p>
            <p className="mv2 leading-normal">
              Visitor voluntarily assumes all risk and danger incidental to the Event, whether occurring prior to,
              during or after the Event. The ticket holder voluntarily agrees that the facility, sponsors, participants,
              the Organizer and all of their respective agents, officers, directors, owners, affiliates, employees and
              consultants (collectively, the “Released Parties”) are expressly released by the ticket holder from any
              claims arising from or related to the Event. Ticket purchasers’ statutory rights as consumers in
              connection with their purchase of tickets through the Event website are not affected by these Terms and
              Conditions.
            </p>
            <p className="mv2 leading-normal">
              By purchasing a ticket, you agree to receive email and SMS notifications related to the event, including
              ticketing information and updates. You may opt-out of these communications at any time by following the
              unsubscribe instructions provided. Your contact information will be shared with the event organizer for
              event-related communications and may be used in accordance with their privacy policy. TBA will never sell
              your data.
            </p>
            <p className="mv2 leading-normal">
              These Terms and Conditions shall be governed by and construed in accordance with the laws of the State of
              Delaware without regard to its conflicts of law principles that require another State’s laws to apply. Any
              claim arising under these Terms and Conditions shall be prosecuted in a federal or state court of
              competent jurisdiction located in the state of Delaware. To the extent permitted by law, any cause of
              action a ticket holder may have with respect to their tickets must be commenced within one (1) year after
              the claim or cause of action arises.
            </p>
            <p className="mv2 leading-normal">
              Should any provision of these Terms and Conditions be determined by a court of competent jurisdiction to
              be illegal, invalid, or otherwise unenforceable, then such provision shall be limited or deleted to the
              minimum extent necessary to comply with applicable laws and the remaining provisions of these Terms and
              Conditions shall survive and continue to be binding and enforceable.
            </p>
            <p className="mv2 leading-normal">
              If you have any queries regarding your ticket order, please contact info@whatstba.com.
            </p>
          </>
        </div>
        <IconX onClick={closeModal} size={28} className="absolute right-0 top-2" />
      </main>
      <div className="dtc-l dtc-m v-mid tr f5-l f6 fw5 ">
        <span
          onClick={() => {
            trackAction({
              action: AMPLITUDE_ACTIONS.CLOSE,
              event: AMPLITUDE_EVENTS.TERMS_CONDITIONS,
            });
            closeModal();
          }}
          className="b--white dib no-underline white noselect dim br-100 b--solid pa2 mt2-l ph3 mr2">
          Close
        </span>
      </div>
    </Modal>
  );
};
