import React, { useEffect } from 'react';
import { useState } from 'react';
import { createLogger } from '@/modules/logging/logger';
import { AMPLITUDE_ACTIONS, AMPLITUDE_EVENTS } from '@/modules/amplitude/events';
import { trackAction } from '@/modules/amplitude/amplitudeBrowserClient';
import { EventProps, OrderProps } from '@/modules/data/types';
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { CheckoutFlow } from '@/lib/constants';
import axios from 'axios';
import { extractFirstAndLastName, formatPrice, round } from '@/lib/index';

const logger = createLogger({
  fileLabel: '/src/components/Event/ApplePayComponent',
});

interface ApplyPayProps {
  getApplePayOrder: (stuff: any) => OrderProps;
  total: number;
  currency: string;
  setMode: (mode: CheckoutFlow) => void;
  clientSecret: string;
  event: EventProps;
  utm_medium?: string;
  utm_source?: string;
}

export const ApplePayComponent: React.FunctionComponent<ApplyPayProps> = ({
  getApplePayOrder,
  total,
  clientSecret,
  setMode,
  currency,
  event,
  utm_medium,
  utm_source,
}) => {
  const [paymentRequest, setPaymentRequest] = useState(null);

  const stripe = useStripe();

  // updating the request
  useEffect(() => {
    if (paymentRequest && total) {
      paymentRequest.update({
        total: {
          label: 'Total',
          amount: Math.round(total * 100),
        },
      });
    }
  }, [paymentRequest, total]);

  useEffect(() => {
    if (!!(stripe && total && clientSecret && !paymentRequest)) {
      let firstName = '';
      let lastName = '';
      let emailAddress = '';
      let zipCode = '';
      let phoneNumber = '';

      const pr = stripe.paymentRequest({
        country: 'US',
        currency: currency.toLocaleLowerCase(),
        total: {
          label: event.name,
          amount: round(total * 100),
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
      });
      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

      pr.on('token', async (ev) => {
        const { name, address_zip } = ev.token.card;
        firstName = extractFirstAndLastName(name).firstName;
        lastName = extractFirstAndLastName(name).lastName;
        zipCode = address_zip;
      });

      pr.on('paymentmethod', async (ev) => {
        // Confirm the PaymentIntent without handling potential next actions (yet).
        setMode(CheckoutFlow.PROCESSING);
        const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false },
        );
        emailAddress = ev.paymentMethod.billing_details.email;
        phoneNumber = ev.paymentMethod.billing_details.phone;

        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete('fail');
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete('success');
          // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent.status === 'requires_action') {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(clientSecret);
            if (error) {
              logger.error('error', error);
              // The payment failed -- ask your customer for a new payment method.
            } else {
              logger.info('success');
              // The payment has succeeded -- show a success message to your customer.
            }
          } else {
            // The payment has succeeded -- show a success message to your customer.
            trackAction({
              action: AMPLITUDE_ACTIONS.CLICK,
              event: AMPLITUDE_EVENTS.CHECKOUT_APPLE_PAY,
              opts: { eventName: event.name, total: formatPrice(total.toString(), true), eventId: event._id },
            });

            await axios.post(
              '/api/ticket',
              {
                order: {
                  ...getApplePayOrder({}),
                  firstName,
                  lastName,
                  applePay: true,
                  emailAddress,
                  zipCode,
                  phoneNumber,
                  token: paymentIntent,
                },
                event,
              },
              { params: { utm_source, utm_medium } },
            );
            setMode(CheckoutFlow.CONFIRMATION);
          }
        }
      });
    }
  }, [stripe, total, clientSecret, paymentRequest]);

  return <>{paymentRequest ? <PaymentRequestButtonElement options={{ paymentRequest }} /> : ''}</>;
};
