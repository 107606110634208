import React, { useEffect } from 'react';
import { useState } from 'react';
import Modal from 'react-modal';
import { createLogger } from '@/modules/logging/logger';
import animationData from '@/static/animations/diced.json';
import Lottie from 'react-lottie';
import { PasswordModalView } from '@/lib/constants';
import FadeIn from 'react-fade-in';
import classNames from 'classnames';
import { trackAction } from '@/modules/amplitude/amplitudeBrowserClient';
import { AMPLITUDE_ACTIONS, AMPLITUDE_EVENTS } from '@/modules/amplitude/events';

const logger = createLogger({
  fileLabel: `components/Event/PasswordModal`,
});

interface PayoutModalProps {
  afterOpenModal?: () => void;
  closeModal?: () => void;
  isOpen?: boolean;
  password: string;
}

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.95)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    backgroundColor: 'black',
    color: 'white',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const PasswordModal: React.FunctionComponent<PayoutModalProps> = ({
  isOpen,
  closeModal,
  afterOpenModal,
  password,
}) => {
  const [passwordInput, setPasswordInput] = useState<string>('');
  const [passwordError, setPasswordError] = useState<boolean>(false);

  const [currentPayoutStep, setCurrentPayoutStep] = useState<PasswordModalView>(PasswordModalView.ENTER_PASSWORD);
  const [processing, setProcessing] = useState<boolean>(false);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    setCurrentPayoutStep(PasswordModalView.ENTER_PASSWORD);
  }, [closeModal]);

  const processPassword = async () => {
    setCurrentPayoutStep(PasswordModalView.PROCESSING);
    setTimeout(() => {
      if (password.toLocaleLowerCase() !== passwordInput.toLocaleLowerCase()) {
        setPasswordError(true);
        setProcessing(false);
        setCurrentPayoutStep(PasswordModalView.ENTER_PASSWORD);
      } else {
        closeModal();
      }
    }, 2000);
  };
  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal">
      {currentPayoutStep === PasswordModalView.PROCESSING && (
        <FadeIn>
          <div className="pb4 dt center">
            <div className="v-mid dtc">
              <Lottie options={defaultOptions} height={200} width={200} />
            </div>
          </div>
        </FadeIn>
      )}
      {currentPayoutStep === PasswordModalView.ENTER_PASSWORD && (
        <div>
          <div className="mb-2 ">
            <h2 className="f3  mv1">Password Required</h2>
          </div>
          <form className="w-100 pt4 mw7 center">
            <div className="dt w-100">
              <div className="mt1 dtc w-48 pb3 ">
                <small className=" db  pb1">Enter passsword</small>
                <input
                  value={passwordInput}
                  className={`bg-transparent white ${classNames({
                    'ba-hover': !passwordError,
                    'ba-hover-error': passwordError,
                  })} pv2 pl2 mr3 w-90`}
                  onChange={(e) => {
                    setPasswordInput(e.currentTarget.value);
                  }}
                />
                {passwordError && <small className="db tl red">Whoops! Are you sure about that?</small>}
              </div>
            </div>
          </form>

          <div className="dtc-l dtc-m v-mid tc f5-l f6 fw5 ">
            <span
              onClick={() => {
                trackAction({
                  action: AMPLITUDE_ACTIONS.CLICK,
                  event: AMPLITUDE_EVENTS.PASSWORD_RESET_COMPLETED
                })
                setProcessing(true);
                processPassword();
              }}
              className="b--white dib no-underline white noselect dim br-100 b--solid pa2 mt2-l ph3 mr2">
              {processing && <i className="fa fa-spinner fa-spin mr2" />}
              {'Tap In'}
            </span>
          </div>
        </div>
      )}
    </Modal>
  );
};
