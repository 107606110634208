import * as React from 'react';
import { PaymentCardSection } from './PaymentCardSection';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import 'cleave.js/dist/addons/cleave-phone.us';
import { formatPrice, getTotalOrderRev } from '@/lib/index';
import axios from 'axios';
import { useState } from 'react';
import { EventProps, EventCartProps, OrderProps } from '@/modules/data/types';
import { createLogger } from '@/modules/logging/logger';
import { CheckoutFlow } from '@/lib/constants';
import { purchaseEvent } from '@/modules/gtag';
import { trackAction } from '@/modules/amplitude/amplitudeBrowserClient';
import { AMPLITUDE_ACTIONS, AMPLITUDE_EVENTS } from '@/modules/amplitude/events';
// import { useRouter } from 'next/router';
// const router = useRouter();

const logger = createLogger({
  fileLabel: `components/Event/PaymentCheckoutForm`,
});
interface PaymentCheckoutProps {
  setMode: (mode: CheckoutFlow) => void;
  mode: CheckoutFlow;
  clientSecret: string;
  order: OrderProps;
  total: number;
  offerBased: boolean;
  autoAccept: boolean;
  event: EventProps;
  utm_medium?: string;
  utm_source?: string;
  cart: {
    [ticketName: string]: EventCartProps;
  };
}

export const PaymentCheckoutForm: React.FunctionComponent<PaymentCheckoutProps> = ({
  total,
  event,
  setMode,
  mode,
  order,
  offerBased,
  autoAccept,
  clientSecret,
  utm_medium,
  utm_source
}) => {
  const [error, setError] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const handlePaidCheckout = async () => {
    try {
      const card = elements.getElement(CardElement);
      const { token } = await stripe.createToken(card);
            
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card,
          billing_details: {
            name: `${order.firstName} ${order.lastName}`,
            email: order.emailAddress,
            phone: order.phoneNumber,
            address: {
              postal_code: order.zipCode,
            },
          },
        },
      });

      if (result.error) {
        setError(result.error.message);
        return setMode(CheckoutFlow.PAYMENT_CHECKOUT);
      }
      
      await axios.post('/api/ticket', {
        order: {
          ...order,
          token: result.paymentIntent,
          cardToken: token,
        },
        event,
      }, { params: { utm_source, utm_medium } });
      purchaseEvent({
        first_name: order.firstName,
        last_name: order.lastName,
        postal_code: order.zipCode,
        email_address: order.emailAddress,
        affiliation: event.organizerName,
        tax: order.stripeFee,
        value: getTotalOrderRev([order]),
        ...(order.offerCode && { coupon: order.offerCode }),
        cart: order.cart,
      });
      return setMode(CheckoutFlow.CONFIRMATION);
    } catch (error) {
      logger.error('Ticket API endpoint error', error);
      return setMode(CheckoutFlow.ERROR);
    }
  };

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
      logger.error('card validation', error);
    } else {
      setError(null);
    }
  };
  return (
    <div className="pa3 w-100">
      <form className="max-w-100 flex flex-col center">
        <div className="dt pb3 mv2 w-full md:w-100">
          <p className="text-md  db pb1">Credit or debit card</p>
          <PaymentCardSection handleChange={handleChange} />
          <div className="card-errors pt1" role="alert">
            {error}
          </div>
        </div>

        {
          <div className="dib w-100 ">
            <span className="fl pt2 font-semibold f3-ns f4 db">
              Total: {formatPrice(total.toString(), false, event.currency)}
            </span>
            <div className="w-full pt-4 flex-col flex">
              <div>
                <div className="flex md:flex-row flex-col-reverse mx-auto  w-fit md:ml-auto md:mr-0 ">
                  <button
                    id="btn-cancel-pay"
                    onClick={(e) => {
                      e.preventDefault();
                      trackAction({
                        action: AMPLITUDE_ACTIONS.BACK,
                        event: AMPLITUDE_EVENTS.CHECKOUT_PAYMENT_BACK,
                        opts: {
                          orderId: order?._id,
                          orderEventId: order?.eventId,
                          orderTicketName: order?.ticketName,
                          orderFirstName: order?.firstName,
                          orderLastName: order?.lastName,
                          orderEmailAddress: order?.emailAddress,
                          orderTotal: order?.total,
                          total,
                          setMode: CheckoutFlow.PAYMENT_CHECKOUT,
                          offerBased,
                          autoAccept
                        },
                        eventData: {
                          eventId: event?._id,
                          eventName: event?.name,
                        },
                      })
                      setMode(CheckoutFlow.USER_CHECKOUT);
                    }}
                    className="   md:mr-0 md:mt-4 py-3 px-5 md:text-3xl md:py-2 md:px-0 md:w-[117px] font-grotesk w-[230px] mx-auto leading-tight rounded-full font-extrabold hover:underline  text-gray-01">
                    Back
                  </button>
                  {!error && stripe && (
                    <button
                      id="btn-accept-pay"
                      onClick={(e) => {
                        e.preventDefault();
                        trackAction({
                          action: AMPLITUDE_ACTIONS.CLICK,
                          event: AMPLITUDE_EVENTS.CHECKOUT_PAYMENT,
                          opts: {
                            orderId: order?._id,
                            orderEventId: order?.eventId,
                            orderTicketName: order?.ticketName,
                            orderFirstName: order?.firstName,
                            orderLastName: order?.lastName,
                            orderEmailAddress: order?.emailAddress,
                            orderTotal: order?.total,
                            total,
                            setMode: CheckoutFlow.PROCESSING,
                            offerBased,
                            autoAccept
                          },
                          eventData: {
                            eventId: event?._id,
                            eventName: event?.name,
                          },
                        })
                        handlePaidCheckout() && setMode(CheckoutFlow.PROCESSING);
                      }}
                      className="  md:mr-0 md:mt-4 py-3 px-6 md:px-[28px] md:text-3xl md:py-2 md:w-fit font-grotesk w-[230px] mx-auto leading-tight rounded-full  bg-green font-extrabold  text-gray-01">
                      {offerBased && !autoAccept ? `Place Hold` : `Purchase`}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        }
      </form>
    </div>
  );
};
