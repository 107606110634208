import FadeIn from 'react-fade-in';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { formatPrice, isEventSoldOut, tbaBlue } from '@/lib/index';
import { TicketSelection } from './';
import classnames from 'classnames';
import { EventCartProps, TicketProps, OfferProps, OrderProps, EventProps } from '@/modules/data/types/';
import { createLogger } from '@/modules/logging/logger';
import customId from 'r2-custom-id';
import { CheckoutFlow } from '@/lib/constants';
import { trackEvent } from '@/modules/gtag';
import { trackAction } from '@/modules/amplitude/amplitudeBrowserClient';
import { AMPLITUDE_ACTIONS, AMPLITUDE_EVENTS } from '@/modules/amplitude/events';
import { ApplePayComponent } from './ApplePayComponent';

const logger = createLogger({
  fileLabel: `components/Event/TicketCheckoutForm`,
});

interface TicketCheckout {
  setMode: (mode: CheckoutFlow) => void;
  cart: {
    [ticketName: string]: EventCartProps;
  };
  setCart: any;
  handleCheckout: any;
  clientSecret: string;
  absorb: boolean;
  setOfferBased: (o: boolean) => void;
  setAutoAccept: (o: boolean) => void;
  setOffers: (o: { [couponCode: string]: OfferProps }) => void;
  offers: {
    [couponCode: string]: OfferProps;
  };
  setTotal: (num: number) => void;
  setWaitlist: (bool: boolean) => void;
  ticketTypes: {
    [ticketName: string]: TicketProps;
  };
  setOfferCode: (code: string) => void;
  scroll?: any;
  getApplePayOrder: (stuff: any) => OrderProps;
  order: OrderProps;
  currency: string;
  event: EventProps;
  utm_medium?: string;
  utm_source?: string;
}

export const TicketCheckoutForm: React.FunctionComponent<TicketCheckout> = ({
  ticketTypes,
  setMode,
  setCart,
  setWaitlist,
  getApplePayOrder,
  currency,
  cart,
  absorb,
  setTotal,
  offers,
  setAutoAccept,
  setOfferCode,
  setOfferBased,
  order,
  clientSecret,
  scroll,
  event,
  utm_medium,
  utm_source,
}) => {
  const [localTotal, setLocalTotal] = useState<number>(0);
  const [localFees, setLocalFees] = useState<number>(0);
  const [localOfferApplied, setLocalOfferApplied] = useState<boolean>(false);
  const [localOfferUnlocked, setLocalOfferUnlocked] = useState<boolean>(false);
  const [localOfferCode, setLocalOfferCode] = useState<string>('');
  const [activatedOfferCode, setActivatedOfferCode] = useState<string>('');
  const [offerError, setOfferError] = useState<string>('');
  const [emptyCart, setEmptyCart] = useState<boolean>(true);
  const [localCart, setLocalCart] = useState<{
    [ticketName: string]: EventCartProps;
  }>({});
  const [offersTemp, setOffersTemp] = useState<{
    [couponCode: string]: OfferProps;
  }>(offers);
  const [offerNotFound, setOfferNotFound] = useState<boolean>(false);
  const [savingsAmount, setSavingsAmount] = useState<number>(0);
  const [openCouponForm, setOpenCouponForm] = useState<boolean>(false);
  const [originalTotal, setOriginalTotal] = useState<number>(localTotal);
  const [localTicketTypes, setLocalTicketTypes] = useState<{
    [ticketName: string]: TicketProps;
  }>(ticketTypes);

  const myRef = useRef(null);
  const myFormRef = useRef(null);
  useEffect(() => {
    if (localTotal) {
      setOriginalTotal(localTotal);
    }
  }, [localTotal]);
  useEffect(() => {
    clearCart();
  }, []);

  const updateTixPrice = (ticket: TicketProps) => {
    const newSetOfAvailTixs: { [name: string]: TicketProps } = {};
    Object.keys(localTicketTypes).forEach((tix) => {
      const ogTicket = localTicketTypes[tix];

      if (ticket.ticketName === ogTicket.ticketName) {
        newSetOfAvailTixs[tix] = ticket;
      } else {
        newSetOfAvailTixs[tix] = ogTicket;
      }
    });

    setLocalTicketTypes(newSetOfAvailTixs);
  };

  // const applyOffer = (e: any) => {
  //   e.preventDefault();
  //   setOfferError('');
  //   let ticketsUnlocked = false;
  //   let offerObj = Object.assign({}, offersTemp);
  //   const offerUp = Object.keys(offersTemp).filter((offer) => offer === localOfferCode);
  //   if (offerUp.length) {
  //     let { quantity, value, ticketLimit, enabled, hidden, amount, uses, compatibleTixs } = offerObj[offerUp[0]];
  //     let newTotal = 0;
  //     let newFees = 0;
  //     let savingsAmt = 0;
  //     let matchedTixs = [];
  //     let compatibleCoupon = true;
  //     if (quantity - uses <= 0 || hidden || !enabled) {
  //       return setOfferError(`This coupon is sold out`);
  //     }
  //     if (compatibleTixs?.length) {
  //       compatibleTixs.forEach((tixName) => {
  //         if (ticketTypes[tixName]) matchedTixs.push(tixName);
  //       });

  //       const newSetOfAvailTixs: { [name: string]: TicketProps } = {};
  //       Object.keys(localTicketTypes).forEach((tix) => {
  //         const ticket = localTicketTypes[tix];
  //         if (matchedTixs.indexOf(tix) !== -1) {
  //           newSetOfAvailTixs[tix] = {
  //             ...ticket,
  //             quantity: ticket.sold + ticketLimit,
  //             sold: ticket.quantity,
  //             max: ticketLimit,
  //             hidden: false,
  //             enabled: true,
  //           };
  //         } else {
  //           newSetOfAvailTixs[tix] = ticket;
  //         }
  //       });
  //       setLocalTicketTypes(newSetOfAvailTixs);
  //       if (Object.keys(newSetOfAvailTixs).length !== Object.keys(compatibleTixs).length) {
  //         setLocalOfferUnlocked(true);
  //         setActivatedOfferCode(localOfferCode);
  //         ticketsUnlocked = true;
  //       }
  //       executeScrolltoTickets();
  //     }
  //     if (quantity - uses > 0 && !hidden && enabled) {
  //       // setLocalOfferUnlocked(false);
  //       let cartLength = Object.keys(localCart).length;
  //       const { ticketLimit } = offerObj[offerUp[0]];
  //       let availLimit = Math.min(ticketLimit, cartLength);
  //       let newCart = localCart;
  //       let ind = 0;
  //       for (const [_ticketId, tix] of Object.entries(localCart)) {
  //         let { fee, price, _id } = tix;
  //         if (compatibleTixs?.length && compatibleTixs?.indexOf(tix.ticketName) === -1) {
  //           setOfferError(
  //             `That code doesn’t apply to this ticket type, please use one of the following tickets: ${compatibleTixs.join(
  //               ', ',
  //             )}`,
  //           );
  //           continue;
  //         }
  //         if (!availLimit) break;
  //         availLimit--;

  //         let newPrice = !value ? Math.max(0, price - amount) : price - price * (value / 100);
  //         savingsAmt += !value ? amount : price * (value / 100);
  //         newCart[_id] = {
  //           price: newPrice,
  //           quantity: tix.quantity,
  //           fee: newPrice ? fee : 0,
  //           ticketName: tix.ticketName,
  //           _id,
  //         };
  //         ind++;
  //       }
  //       for (var ticket in newCart) {
  //         const { price, fee } = newCart[ticket];
  //         if (price) {
  //           newTotal += price + fee;
  //           newFees += fee;
  //         }
  //       }
  //       setLocalCart(newCart);
  //       setLocalTotal(parseFloat(newTotal.toFixed(2)));
  //       setTotal(parseFloat(newTotal.toFixed(2)));
  //       setLocalFees(parseFloat(newFees.toFixed(2)));
  //       setSavingsAmount(savingsAmt);
  //       setLocalOfferApplied(true);
  //       // setOfferError('');
  //     }
  //     if (emptyCart && !ticketsUnlocked && compatibleTixs?.length) {
  //       setOfferError(
  //         compatibleTixs.length
  //           ? `Please select tickets one of the following tickets first:  ${compatibleTixs.join(', ')}`
  //           : `Please select tickets first`,
  //       );
  //     }
  //   } else {
  //     setOfferNotFound(true);
  //   }
  // };

  const applyOffer = (e: any) => {
    e.preventDefault();
    setOfferError('');

    const offer = offersTemp[localOfferCode];
    if (!offer) {
      setOfferNotFound(true);
      return;
    }

    const { quantity, value, ticketLimit, enabled, hidden, amount, uses, compatibleTixs } = offer;
    if (quantity - uses <= 0 || hidden || !enabled) {
      setOfferError(`This coupon is sold out`);
      return;
    }

    if (emptyCart) {
      setOfferError(`Please select tickets first`);
      return;
    }
    let cartLength = Object.keys(localCart).length;
    let availLimit = Math.min(ticketLimit, cartLength);
    let appliedCount = 0; // Track how many times the coupon has been applied

    let newTotal = 0;
    let newFees = 0;
    let savingsAmt = 0;
    let newCart = { ...localCart };
    let compatibleCoupon = true;

    Object.entries(newCart).forEach(([ticketId, tix]) => {
      if (!compatibleTixs.includes(tix.ticketName) && compatibleTixs.length) {
        compatibleCoupon = false;
        return setOfferError(
          `That code doesn’t apply to this ticket type, please use one of the following tickets: ${compatibleTixs.join(
            ', ',
          )}`,
        );
      }
      const limit = appliedCount >= ticketLimit;

      let newPrice =
        limit || !compatibleCoupon
          ? tix.price
          : value
          ? tix.price - tix.price * (value / 100)
          : Math.max(0, tix.price - amount);
      savingsAmt += limit || !compatibleCoupon ? 0 : value ? tix.price * (value / 100) : amount;

      newCart[ticketId] = {
        ...tix,
        price: newPrice,
        fee: newPrice ? (absorb ? 0 : tix.fee) : 0,
      };

      newFees += newPrice ? (absorb ? 0 : tix.fee) : 0;
      newTotal += newPrice + newFees;
      appliedCount++; // Increment the count each time the coupon is applied
    });

    setLocalCart(newCart);
    setLocalTotal(newTotal);
    setTotal(newTotal);
    setLocalFees(newFees);
    setSavingsAmount(savingsAmt);
    setLocalOfferApplied(compatibleCoupon ? true : false);

    updateTicketAvailability(compatibleTixs, compatibleCoupon, ticketLimit);
  };

  const updateTicketAvailability = (compatibleTixs, compatibleCoupon, ticketLimit) => {
    const newSetOfAvailTixs = { ...localTicketTypes };

    Object.keys(newSetOfAvailTixs).forEach((tix) => {
      if (compatibleTixs.includes(tix)) {
        const ticket = newSetOfAvailTixs[tix];
        newSetOfAvailTixs[tix] = {
          ...ticket,
          quantity: ticket.quantity + ticketLimit,
          enabled: true,
          hidden: false,
        };
        if (!localTicketTypes[tix].enabled) {
          setLocalOfferUnlocked(compatibleCoupon ? true : false);
        }
      }
    });
    logger.info('newSetOfAvailTixs', newSetOfAvailTixs, compatibleTixs);
    setLocalTicketTypes(newSetOfAvailTixs);
    setActivatedOfferCode(compatibleCoupon ? localOfferCode : '');
    // executeScrolltoTickets();
  };

  const clearCart = () => {
    setLocalCart({});
    setEmptyCart(true);
    setLocalFees(0);
  };
  const finalizeOrder = () => {
    setOfferCode(offerNotFound ? '' : localOfferCode);
    // scroll();
    // setMode(CheckoutFlow.USER_CHECKOUT);
  };

  const joinWaitList = () => {
    setWaitlist(true);
    setMode(CheckoutFlow.USER_CHECKOUT);
  };

  const updateOffer = (code: string) => {
    setLocalOfferCode(code);
    setOfferNotFound(false);
    setLocalTotal(originalTotal);
    setLocalOfferApplied(false);
  };
  const resetOffer = () => {
    setLocalOfferCode('');
    setOfferError('');
    setOfferNotFound(false);
    setLocalTotal(originalTotal);
    setLocalOfferApplied(false);
    setOffersTemp(offers);
    setLocalOfferUnlocked(false);
    // setLocalTicketTypes(ticketTypes);
    setOpenCouponForm(false);
  };

  useEffect(() => {
    setOfferBased(isCartOfferBased());
  }, [cart]);

  const isCartOfferBased = () => {
    let isOfferBased = false;
    Object.keys(cart).forEach((tixId) => {
      const ticket = ticketTypes[cart[tixId].ticketName];
      if (ticket.ticketType === 'offer') {
        isOfferBased = true;
        if (ticket.autoAcceptOffers) {
          setAutoAccept(true);
        }
      }
    });
    return isOfferBased;
  };
  const updateAddCart = (ticket: TicketProps) => {
    setWaitlist(false);
    if (!localOfferUnlocked) {
      resetOffer();
    }
    const ticketId = customId({});
    let newTotal = 0;
    let savingsAmt = 0;
    let newFees = localFees;
    newFees += ticket.fee;
    const updatedCart = Object.assign(localCart, {
      [ticketId]: {
        price: ticket.price,
        quantity: 1,
        ticketName: ticket.ticketName,
        fee: ticket.fee,
        _id: ticketId,
      },
    });

    for (var tix in updatedCart) {
      const { ticketName } = updatedCart[tix];
      updatedCart[tix] = {
        ...localCart[tix],
        price: localTicketTypes[ticketName].price,
        fee: localTicketTypes[ticketName].fee,
      };
      newTotal += updatedCart[tix].price + (absorb ? 0 : updatedCart[tix].fee);
    }

    setSavingsAmount(savingsAmt);
    trackEvent({ action: 'add_to_cart', category: 'event_page', label: ticket.ticketName });
    setLocalCart(updatedCart);
    setCart(updatedCart);
    setLocalFees(newFees);
    setLocalTotal(parseFloat(newTotal.toFixed(2)));
    setTotal(parseFloat(newTotal.toFixed(2)));
    setEmptyCart(Object.keys(localCart).length === 0);
  };

  const updateRemoveCart = async (ticket: TicketProps) => {
    resetOffer();
    let totalFees = 0;
    const tixToDelete = Object.keys(localCart)
      .filter((tix) => localCart[tix].ticketName === ticket.ticketName)
      .sort((a, b) => localCart[a].price - localCart[b].price)[0];

    delete localCart[tixToDelete];

    let newTotal = 0;

    for (var tixId in localCart) {
      const { ticketName } = localCart[tixId];
      newTotal += localTicketTypes[ticketName].price + localTicketTypes[ticketName].fee;
      totalFees += localTicketTypes[ticketName].fee;
      localCart[tixId] = {
        ...localCart[tixId],
        price: localTicketTypes[ticketName].price,
        fee: localTicketTypes[ticketName].fee,
      };
    }

    setLocalCart(localCart);
    setCart(localCart);
    trackEvent({
      action: 'remove_from_cart',
      category: 'event_page',
      label: ticket.ticketName,
      value: ticket.price,
      currency,
    });
    setLocalFees(totalFees);
    setLocalTotal(parseFloat(newTotal.toFixed(2)));
    setTotal(parseFloat(newTotal.toFixed(2)));
    setEmptyCart(Object.keys(localCart).length === 0);
  };

  const filterTixs = useCallback(
    (
      tix: string,
      ticketTypes: {
        [ticketName: string]: TicketProps;
      },
    ) => !ticketTypes[tix].hidden && ticketTypes[tix].enabled,
    [],
  );

  const executeScrolltoTickets = () => {
    myFormRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    // document.getElementById('tickets').scrollTop += 50;
  };
  const executeScrolltoOffer = () => {
    myRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    document.getElementById('offer').scrollTop += 10;
  };
  useEffect(() => {
    if (openCouponForm) {
      executeScrolltoOffer();
    }
  }, [openCouponForm]);

  const ticketsContainerRef = useRef(null);

  return (
    <div ref={myFormRef} className=" mx-auto max-w-[1000px] pb-11" id="tickets">
      <p className="px-5 pt-11 pb-6 text-3xl font-extrabold text-gray-01 md:px-10">Tickets</p>
      <div className="mt-2 h-[1px] bg-white opacity-25"></div>
      <form ref={ticketsContainerRef} className="center max-w-2xl md:mx-0 md:max-w-full">
        {Object.keys(localTicketTypes)
          .filter((tix) => filterTixs(tix, localTicketTypes))
          .sort((a, b) => {
            if (event.slug === 'diasporacomedyatl') {
              const availableTicketsA = localTicketTypes[a].quantity - localTicketTypes[a].sold;
              const availableTicketsB = localTicketTypes[b].quantity - localTicketTypes[b].sold;

              // Place sold-out tickets at the end
              if (availableTicketsA === 0 && availableTicketsB !== 0) return 1;
              if (availableTicketsB === 0 && availableTicketsA !== 0) return -1;

              // Sort by available tickets (descending order)
              if (availableTicketsA !== availableTicketsB) {
                return availableTicketsB - availableTicketsA;
              }

              // Sort by price (ascending order)
              return localTicketTypes[a].price - localTicketTypes[b].price;
            }
            return ticketTypes[a].price < ticketTypes[b].price ? -1 : 1;
          })
          .map((curr, ind) => {
            return (
              <TicketSelection
                id={ind}
                key={curr}
                cart={localCart}
                updateTixPrice={updateTixPrice}
                updateAddCart={updateAddCart}
                currency={currency}
                updateRemoveCart={updateRemoveCart}
                ticketType={localTicketTypes[curr]}
                event={event}
              />
            );
          })}
        <FadeIn>
          {isEventSoldOut(ticketTypes) && (
            <div className="dib noselect br-100 pa2  ph3 font-brownb text-xl text-gray-01 transition-all ease-out ">
              <span
                onClick={() => {
                  trackAction({
                    action: AMPLITUDE_ACTIONS.CLICK,
                    event: AMPLITUDE_EVENTS.WAITLIST_JOIN,
                    opts: {
                      setMode: CheckoutFlow.USER_CHECKOUT,
                    },
                    eventData: {
                      eventId: event?._id,
                      eventName: event?.name,
                    },
                  });
                  joinWaitList();
                }}
                className="pr2  mt2  noselect">
                Join waitlist?
              </span>
            </div>
          )}
          {openCouponForm ? (
            <>
              <div className="flex flex-wrap items-end gap-3 px-5 pt-4 md:px-10" id="offer" ref={myRef}>
                <div className="w-[180px] md:w-auto">
                  <input
                    value={localOfferCode}
                    placeholder="HAVE CODE?"
                    className={`${classnames({
                      'glass-background mt-1 h-12 rounded-full text-center font-semibold text-white':
                        !offerNotFound && !offerError,
                      'ba-hover-error': offerNotFound || offerError,
                    })} pa2 mr3 w-90 md:w-48`}
                    onChange={(e) => {
                      setOfferError('');
                      if (!localOfferApplied) {
                        updateOffer(e.currentTarget.value.toLocaleUpperCase());
                      }
                    }}
                  />
                  {offerError && <small className="db tl red">{offerError}</small>}
                  {offerNotFound && <small className="db tl red">Code is invalid. Enter a valid code</small>}
                </div>
                <div className="  ">
                  <button
                    disabled={localOfferApplied}
                    className={` rounded-full bg-green px-5 py-2 font-semibold text-white transition-all ${classnames({
                      'animate-wiggle': offerNotFound,
                    })}`}
                    onClick={(e) => {
                      trackAction({
                        action: AMPLITUDE_ACTIONS.CLICK,
                        event: AMPLITUDE_EVENTS.OFFER_ACCEPT_APPLY,
                        opts: {
                          code: localOfferCode,
                        },
                        eventData: {
                          eventId: event?._id,
                          eventName: event?.name,
                        },
                      });
                      applyOffer(e);
                    }}>
                    {localOfferApplied || localOfferUnlocked ? `Applied!` : `Apply`}
                  </button>
                  <button
                    className={` ml-3 font-semibold  text-white hover:underline`}
                    onClick={() => {
                      trackAction({
                        action: AMPLITUDE_ACTIONS.CLOSE,
                        event: AMPLITUDE_EVENTS.OFFER_CANCEL_APPLY,
                        eventData: {
                          eventId: event?._id,
                          eventName: event?.name,
                        },
                      });
                      setOpenCouponForm(false);
                      resetOffer();
                      clearCart();
                    }}>
                    {`Cancel`}
                  </button>
                </div>
              </div>

              {localOfferApplied && (
                <p className="px-10 mt-3 uppercase text-xs text-white font-semibold w-full">
                  {localOfferCode} discount applied!{' '}
                  <span className="text-green-500">{formatPrice(savingsAmount.toString(), true, currency)}</span>
                </p>
              )}
              {localOfferUnlocked && (
                <p className="px-10 mt-2 uppercase text-xs text-white font-semibold w-full">
                  {activatedOfferCode} applied!
                </p>
              )}
            </>
          ) : (
            <div className="noselect cursor-pointer py-3  px-5 text-sm font-semibold  text-gray-01    transition-all  ease-out hover:underline md:px-10 ">
              <span
                onClick={() => {
                  trackAction({
                    action: AMPLITUDE_ACTIONS.OPEN,
                    event: AMPLITUDE_EVENTS.EVENT_CODE_DISCOUNT,
                    eventData: {
                      eventId: event?._id,
                      eventName: event?.name,
                    },
                  });
                  setOpenCouponForm(true);
                }}
                className="pr2  mt2  noselect">
                Have a code?
              </span>
            </div>
          )}
        </FadeIn>
      </form>
      {!emptyCart && (
        <div className="fixed text-white text-xl bottom-0 left-0 bg-tbaBlue w-full py-2 px-4 flex justify-between items-center z-10">
          <span className=" text-xl sm:text-2xl font-semibold ">
            {/* <ApplePayComponent
              total={localTotal}
              currency={currency}
              getApplePayOrder={getApplePayOrder}
              setMode={setMode}
              clientSecret={clientSecret}
              event={event}
              utm_medium={utm_medium}
              utm_source={utm_source}
            /> */}
            Total: {formatPrice(localTotal.toString(), false, currency)}
            {localOfferApplied && (
              <span className="">
                <span className="text-green text-lg"> {`(${formatPrice(savingsAmount.toString(), true)} OFF)`}</span>
              </span>
            )}
            {localFees > 0 && !absorb && (
              <span className="font-medium text-lg block">{` (${formatPrice(
                localFees.toString(),
                true,
                currency,
              )} Fees)`}</span>
            )}
          </span>
          <button
            id="btn-submit"
            type="submit"
            onClick={() => {
              trackAction({
                action: AMPLITUDE_ACTIONS.CLICK,
                event: AMPLITUDE_EVENTS.CHECKOUT_PAYMENT,
              });
              finalizeOrder();
              scroll();
              setMode(CheckoutFlow.USER_CHECKOUT);
            }}
            className="mx-auto w-[230px] rounded-full bg-green py-3 px-5 font-grotesk font-extrabold leading-tight text-white md:mr-0 md:w-[150px]  md:py-2 md:px-0 text-xl md:text-3xl">
            Next
          </button>
        </div>
      )}
    </div>
  );
};
