import React, { useEffect, useRef, useState } from 'react';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.us';
import { formatPrice, pink, purple, tbaBlue, validateEmail } from '@/lib/index';
import { createLogger } from '@/modules/logging/logger';
import classNames from 'classnames';
import { CheckoutFlow } from '@/lib/constants';
import { EventProps, OrderProps } from '@/modules/data/types';
import { Upload } from '../Dashboard/shared/Upload';
import { TermsModal } from './TermsModal';
import { CheckBoxInput } from '../shared/FormInputs';
import 'react-phone-number-input/style.css';
import PhoneInput, { Country } from 'react-phone-number-input';
import { phone } from 'phone';
import axios from 'axios';
import { AMPLITUDE_ACTIONS, AMPLITUDE_EVENTS } from '@/modules/amplitude/events';
import { trackAction } from '@/modules/amplitude/amplitudeBrowserClient';
import { ApplePayComponent } from './ApplePayComponent';

const logger = createLogger({
  fileLabel: `components/EventUserCheckoutForm`,
});
interface EventCheckoutProps {
  setMode: (mode: CheckoutFlow) => void;
  total: number;
  event: EventProps;
  firstName: string;
  offerBased: boolean;
  waitlist: boolean;
  autoAccept: boolean;
  setFirstName: (name: string) => void;
  lastName: string;
  setLastName: (name: string) => void;
  phoneNumber: string;
  setPhoneNumber: (phone: string) => void;
  setZipCode: (zip: string) => void;
  zipCode: string;
  currency: string;
  eventCountry: Country;
  emailAddress: string;
  setEmailAddress: (addy: string) => void;
  handleCheckout: (fields?: { [field: string]: string }) => void;
  scroll: any;
  extraFields: EventProps['extraFields'];
  clientSecret: string;
  getApplePayOrder: (stuff: any) => OrderProps;
  utm_medium?: string;
  utm_source?: string;
}

export const UserCheckoutForm: React.FunctionComponent<EventCheckoutProps> = ({
  total,
  handleCheckout,
  setMode,
  currency,
  clientSecret,
  firstName,
  getApplePayOrder,
  waitlist,
  setFirstName,
  autoAccept,
  lastName,
  setLastName,
  phoneNumber,
  setZipCode,
  zipCode,
  offerBased,
  setPhoneNumber,
  emailAddress,
  eventCountry,
  setEmailAddress,
  scroll,
  extraFields,
  event,
  utm_medium,
  utm_source,
}) => {
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [country, setCountry] = useState<Country>(eventCountry ?? 'US');
  const [emailAddrressError, setEmailAddressError] = useState<boolean>(false);
  const [zipCodeError, setZipCodeError] = useState<boolean>(false);
  const [termsConditionError, setTermsConditionError] = useState<boolean>(false);
  const [termsCondition, setTermsCondition] = useState<boolean>(false);
  const [extraFormFields, setExtraFormFields] = useState<EventProps['extraFields']>(extraFields);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [references, setReferences] = useState<{ [field: string]: string }>({});
  const refs = {};
  extraFormFields?.forEach((fields) => {
    fields['fields'].forEach((field) => {
      refs[field.name] = React.createRef();
    });
  });

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const inputConfig: EventProps['extraFields'] = [
    {
      fields: [
        {
          id: 'input-first-name',
          label: 'First Name',
          placeholder: 'Enter your first name',
          name: 'firstName',
          value: firstName,
          set: setFirstName,
          required: true,
          // type: 'text',
          error: firstNameError,
          errorText: 'Please enter in a first name',
        },
        {
          id: 'input-last-name',
          placeholder: 'Enter your last name',
          label: 'Last Name',
          name: 'lastName',
          // type: 'text',
          value: lastName,
          set: setLastName,
          required: true,
          error: lastNameError,
          errorText: 'Please enter in a last name',
        },
      ],
    },
    {
      fields: [
        {
          id: 'input-phone-number',
          label: 'Phone Number',
          name: 'phoneNumber',
          type: 'text',
          value: phoneNumber,
          set: setPhoneNumber,
          hidden: false,
          required: event.slug === 'bakedvilla' ? false : true,
          placeholder: 'Enter your phone number',
          error: phoneNumberError,
          errorText: 'Please enter in a valid phone number',
        },
        {
          id: 'input-zip-code',
          label: 'Postal Code',
          // type: 'text',
          name: 'zipCode',
          value: zipCode,
          placeholder: 'Enter your postal code',
          set: setZipCode,
          onFocusOut: 'zipCode',
          hidden: false,
          required: true,
          error: zipCodeError,
          errorText: 'Please enter in a valid postal code',
        },
      ],
    },
    {
      fields: [
        {
          id: 'input-email',
          label: 'Email Address',
          name: 'emailAddress',
          value: emailAddress,
          set: setEmailAddress,
          required: true,
          error: emailAddrressError,
          placeholder: 'Enter your email address',
          errorText: 'Please enter in a valid email address ',
        },
      ],
    },
    ...extraFormFields,
  ];

  const isEventReady = async () => {
    const firstNameCheck = await checkForErrors({ firstName });
    const lastNameCheck = await checkForErrors({ lastName });
    const phoneNumberCheck = await checkForErrors({ phoneNumber });
    const emailAddressCheck = await checkForErrors({ emailAddress });
    const zipCodeCheck = await checkForErrors({ zipCode });
    const termsCheck = await checkForErrors({ termsCondition });
    const extraFieldsCheck = checkExtraFields();

    return (
      !firstNameCheck &&
      !lastNameCheck &&
      !emailAddressCheck &&
      !zipCodeCheck &&
      !termsCheck &&
      !phoneNumberCheck &&
      !extraFieldsCheck
      // !ageConditionCheck &&
    );
  };
  const onCheckout = async () => {
    scroll();
    if (await isEventReady()) {
      handleCheckout(getExtraFields());
    } else {
      logger.error('Missing info');
    }
  };

  const getExtraFields = () => {
    if (!extraFormFields || !extraFormFields?.length) return null;
    const responses = {};
    Object.keys(refs).forEach((key) => {
      if (references[key]) {
        responses[key] = references[key];
      } else {
        responses[key] = refs[key].current?.value;
      }
    });
    return responses;
  };
  const getUploadedFields = (url: string, refName: string) => {
    setReferences({ ...references, [refName]: url });
  };

  const checkExtraFields = () => {
    if (!extraFormFields || !extraFormFields?.length) return null;
    const responses = {};
    let errors = false;
    Object.keys(refs).forEach((key) => {
      const getExtraField = extraFormFields
        .find((field) => field.fields.find((field) => field.name === key))
        .fields.find((field) => field.name === key);
      if (getExtraField?.required && !refs[key].current?.value) {
        const newField = { ...getExtraField, error: true };
        const newExtraFields = extraFormFields.map((field) => {
          if (field.fields.find((field) => field.name === key)) {
            return { ...field, fields: field.fields.map((field) => (field.name === key ? newField : field)) };
          }
          return field;
        });
        setExtraFormFields(newExtraFields);
        errors = true;
      } else {
        const newField = { ...getExtraField, error: false };
        const newExtraFields = extraFormFields.map((field) => {
          if (field.fields.find((field) => field.name === key)) {
            return { ...field, fields: field.fields.map((field) => (field.name === key ? newField : field)) };
          }
          return field;
        });
        setExtraFormFields(newExtraFields);
      }
      responses[key] = refs[key].current?.value;
    });
    return errors;
  };

  const checkForErrors = async (item) => {
    const key = Object.keys(item)[0];
    switch (key) {
      case 'firstName':
        if (item[key].length === 0) {
          setFirstNameError(true);
          return true;
        } else {
          setFirstNameError(false);
          return false;
        }
      case 'lastName':
        if (item[key].length === 0) {
          setLastNameError(true);
          return true;
        } else {
          setLastNameError(false);
          return false;
        }
      case 'emailAddress':
        if (!validateEmail(item[key])) {
          setEmailAddressError(true);
          return true;
        } else {
          setEmailAddressError(false);
          return false;
        }
      case 'zipCode':
        type ZipCodeResponse = {
          city: string;
          city_en: string;
          country_code: string;
          latitude: string;
          longitude: string;
          postal_code: string;
          province: string;
          province_code: string;
          state: string;
          state_code: string;
          state_en: string;
        };
        const zipCode = item[key];
        try {
          const {
            data: { results },
          }: { data: { results: { [zip: string]: ZipCodeResponse[] } } } = await axios.get(
            `https://app.zipcodebase.com/api/v1/search?apikey=b295fb60-51d8-11ee-9e29-39c6322add6e&codes=${item[key]}`,
          );
          const getCountries =
            results[zipCode]?.filter(
              (result) => result.country_code === country || result.country_code === eventCountry,
            ) ?? [];

          if (!(getCountries.length > 0)) {
            setZipCodeError(true);
            return true;
          } else {
            setZipCodeError(false);
            return false;
          }
        } catch (error) {
          return logger.error(error);
        }
      case 'phoneNumber':
        const { isValid } = phone(item[key], { country });
        logger.info({ isValid }, item[key], { country });
        if (!isValid && event.slug !== 'bakedvilla') {
          setPhoneNumberError(true);
          return true;
        } else {
          setPhoneNumberError(false);
          return false;
        }
      case 'termsCondition':
        if (!item[key]) {
          setTermsConditionError(true);
          return true;
        } else {
          setTermsConditionError(false);
          return false;
        }
    }
  };
  const phoneInput = useRef(null);
  useEffect(() => {
    if (phoneInput.current) {
      phoneInput.current.style.background = 'transparent';
      phoneInput.current.style['font-weight'] = 500;
    }
  }, [phoneInput.current]);

  const isOffer = total && offerBased && !autoAccept;

  return (
    <div className=" text-gray-01 max-w-[1000px] mx-auto ">
      <TermsModal closeModal={closeModal} isOpen={modalIsOpen} />
      <div className="pt-11  w-full mb-4 md:mb-0">
        <p className=" text-3xl pb-6 font-extrabold text-gray-01">{waitlist ? 'Waitlist' : 'Checkout'}</p>
        <div className="h-[1px] mt-2 bg-white opacity-25"></div>
      </div>
      <form className="w-full center">
        {inputConfig.map((fieldGroup, key) => {
          const fieldOne = fieldGroup.fields[0];

          if (fieldGroup.fields.length === 2) {
            const fieldTwo = fieldGroup.fields[1];
            if (fieldOne.hidden && fieldTwo.hidden) {
              return <></>;
            }
            return (
              <div key={key} className="w-full flex">
                <div key={fieldOne.name} className="md:mb-3 gap-[20px] w-100 md:flex md:mt-4">
                  <div className="md:w-2/4 w-full md:mb-0 mb-3 md:pr-2">
                    <label className="text-md mb-2 ">{fieldOne.label}</label>
                    <div
                      className={` text-white   ${classNames({
                        '': !fieldOne.error,
                        'ba-hover-error rounded': fieldOne.error,
                      })} overflow-visible`}>
                      {fieldOne.name === 'phoneNumber' ? (
                        // <Cleave
                        //   options={{ phone: true, phoneRegionCode: 'US' }}
                        //   placeholder={fieldOne.placeholder}
                        //   value={fieldOne.value}
                        //   onChange={(event) => {
                        //     fieldOne.set(event.currentTarget.value);
                        //     checkForErrors({
                        //       [fieldOne.name]: event.currentTarget.value,
                        //     });
                        //   }}
                        //   className="pl2  placeholder:opacity-3 placeholder:font-medium rounded glass-background input-reset h-12 w-full "
                        // />
                        <PhoneInput
                          ref={phoneInput}
                          id={fieldOne.id}
                          defaultCountry={country}
                          //   value={fieldOne.value}
                          placeholder={fieldOne.placeholder}
                          onCountryChange={(country) => setCountry(country)}
                          value={fieldOne.value}
                          onChange={(event) => {
                            fieldOne.set(event);
                            checkForErrors({
                              [fieldOne.name]: event,
                            });
                          }}
                          className={`pl2 white placeholder:opacity-3 black placeholder:font-bold rounded bg-transparent glass-background input-reset h-12 w-full `}
                        />
                      ) : (
                        <>
                          {fieldOne.type === 'dropdown' && (
                            <select
                              id={fieldOne.id}
                              ref={refs[fieldOne.name]}
                              className={`pl2 placeholder:opacity-3 placeholder:font-medium rounded glass-background input-reset h-12 w-full`}
                              aria-label="Default select example">
                              <option selected className="text-gray-700 font-medium"></option>
                              {fieldOne.options.map((opt) => (
                                <option key={opt} value={opt}>
                                  {opt}
                                </option>
                              ))}
                            </select>
                          )}
                          {/* {fieldOne.type === 'upload' && (
                            <div>
                              <Upload setFile={setResume} types={['pdf', 'doc', 'docx']} />
                            </div>
                          )} */}
                          {fieldOne.type === 'text' && (
                            <input
                              id={fieldOne.id}
                              placeholder={fieldOne.placeholder}
                              // value={fieldOne.value}
                              ref={refs[fieldOne.name]}
                              onChange={(event) => {
                                // fieldOne.set(event.currentTarget.value);
                                checkForErrors({
                                  [fieldOne.name]: event.currentTarget.value,
                                });
                              }}
                              className={`mt-[3px] pl2 text-gray-01 placeholder:opacity-3 placeholder:font-medium rounded glass-background input-reset h-12 w-full`}
                            />
                          )}
                          {!fieldOne.type && (
                            <input
                              id={fieldOne.id}
                              placeholder={fieldOne.placeholder}
                              value={fieldOne.value}
                              onBlur={(e) =>
                                fieldOne.onFocusOut &&
                                checkForErrors({
                                  [fieldOne.name]: e.currentTarget.value,
                                })
                              }
                              onChange={(event) => {
                                fieldOne.set(event.currentTarget.value);
                                !fieldOne.onFocusOut &&
                                  checkForErrors({
                                    [fieldOne.name]: event.currentTarget.value,
                                  });
                              }}
                              className={`pl2 mt-[3px]  placeholder:opacity-3 placeholder:font-medium rounded glass-background input-reset h-12 w-full`}
                            />
                          )}
                        </>
                      )}
                    </div>
                    {fieldOne.error && <small className="d mt-2  tl red">{fieldOne.errorText}</small>}
                  </div>
                  <div className="dib  md:w-2/4 w-full md:mb-0 mb-3 ">
                    <label className=" text-md mb-2   ">{fieldTwo.label}</label>

                    <div
                      className={` ${classNames({
                        '': !fieldTwo.error,
                        'ba-hover-error rounded': fieldTwo.error,
                      })} overflow-visible`}>
                      {fieldTwo.name === 'phoneNumber' ? (
                        // <Cleave
                        //   options={{ phone: true, phoneRegionCode: 'US' }}
                        //   placeholder={fieldTwo.placeholder}
                        //   value={fieldTwo.value}
                        //   onChange={(event) => {
                        //     fieldTwo.set(event.currentTarget.value);
                        //     checkForErrors({
                        //       [fieldTwo.name]: event.currentTarget.value,
                        //     });
                        //   }}
                        //   className="pl2 mt-[3px] mb-1  placeholder:font-medium rounded glass-background input-reset h-12 w-full"
                        // />
                        <>
                          <PhoneInput
                            ref={phoneInput}
                            id={fieldTwo.id}
                            defaultCountry={country}
                            placeholder={fieldTwo.placeholder}
                            onCountryChange={(country) => setCountry(country)}
                            value={fieldTwo.value}
                            onChange={(event) => {
                              logger.info(event);
                              fieldTwo.set(event);
                              checkForErrors({
                                [fieldTwo.name]: event,
                              });
                            }}
                            className={`pl2 white placeholder:opacity-3 black placeholder:font-bold rounded bg-transparent glass-background input-reset h-12 w-full `}
                          />
                        </>
                      ) : (
                        <>
                          {fieldTwo.type === 'dropdown' && (
                            <select
                              id={fieldTwo.id}
                              ref={refs[fieldTwo.name]}
                              className={`pl2 mt-[3px] placeholder:font-medium rounded glass-background input-reset h-12 w-full`}
                              aria-label="Default select example">
                              <option selected className="text-gray-700 font-medium">
                                {/* {fieldOne.placeholder} */}
                              </option>
                              {fieldTwo.options.map((opt) => (
                                <option className="" value={opt}>
                                  {opt}
                                </option>
                              ))}
                            </select>
                          )}
                          {fieldTwo.type === 'text' && (
                            <input
                              id={fieldTwo.id}
                              placeholder={fieldTwo.placeholder}
                              ref={refs[fieldTwo.name]}
                              onChange={(event) => {
                                // fieldTwo.set(event.currentTarget.value);
                                checkForErrors({
                                  [fieldTwo.name]: event.currentTarget.value,
                                });
                              }}
                              className={`mt-[3px] pl2 placeholder:opacity-3 placeholder:font-medium rounded glass-background input-reset h-12 w-full`}
                            />
                          )}
                          {fieldTwo.type === 'boolean' && (
                            <div className={`pt2 pb1 tl`}>
                              {/* <small className="db pt2 pb1 mid-gray ">{fieldTwo.placeholder}</small> */}
                              <input
                                id={fieldTwo.id}
                                type="radio"
                                name="drone"
                                value={'yes'}
                                ref={refs[fieldTwo.name]}
                                className="pl2 pb2  input-reset  bn w-90 "
                              />
                              <label>Yes</label>
                              <input
                                id={fieldTwo.id}
                                type="radio"
                                ref={refs[fieldTwo.name]}
                                name="drone"
                                value={'true'}
                                className="pl2 pb2  input-reset  bn w-90 bg-white"
                              />
                              <label>No</label>
                            </div>
                          )}
                          {fieldTwo.type === 'fileUpload' && (
                            <div>
                              <Upload
                                getUploadedFields={getUploadedFields}
                                refName={fieldTwo.name}
                                buttonText="Resume"
                                setFile={(file) => fieldTwo.set(file)}
                              />
                            </div>
                          )}
                          {!fieldTwo.type && (
                            <input
                              id={fieldTwo.id}
                              placeholder={fieldTwo.placeholder}
                              value={fieldTwo.value}
                              ref={refs[fieldTwo.name]}
                              onChange={(event) => {
                                fieldTwo.set(event.currentTarget.value);
                                checkForErrors({
                                  [fieldTwo.name]: event.currentTarget.value,
                                });
                              }}
                              className={` mt-[3px] pl2 placeholder:opacity-3 placeholder:font-medium rounded glass-background input-reset h-12 w-full`}
                            />
                          )}
                        </>
                      )}
                    </div>
                    {fieldTwo.error && <small className="db mt-2 tl red">{fieldTwo.errorText}</small>}
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div key={fieldOne.name} className="md:my-3 w-100 ">
                <label className=" text-md font-grotesk-md mb-2 ">{fieldOne.label}</label>
                <div
                  key={fieldOne.name}
                  className={` ${classNames({
                    '': !fieldOne.error && fieldOne.type !== 'upload',
                    'ba-hover-error rounded': fieldOne.error && fieldOne.type !== 'upload',
                  })}`}>
                  {fieldOne.type === 'text' && (
                    <input
                      id={fieldOne.id}
                      placeholder={fieldOne.placeholder}
                      ref={refs[fieldOne.name]}
                      onChange={(event) => {
                        checkForErrors({
                          [fieldOne.name]: event.currentTarget.value,
                        });
                      }}
                      className=" mt-[3px] pl2 placeholder:opacity-3 placeholder:font-medium rounded glass-background input-reset h-12 w-full"
                    />
                  )}
                  {fieldOne.type === 'number' && (
                    <input
                      id={fieldOne.id}
                      type="number"
                      placeholder={fieldOne.placeholder}
                      ref={refs[fieldOne.name]}
                      onChange={(event) => {
                        checkForErrors({
                          [fieldOne.name]: event.currentTarget.value,
                        });
                      }}
                      className=" mt-[3px] pl2 placeholder:opacity-3 placeholder:font-medium rounded glass-background input-reset h-12 w-full"
                    />
                  )}
                  {fieldOne.type === 'dropdown' && (
                    <select
                      id={fieldOne.id}
                      ref={refs[fieldOne.name]}
                      className={`mt-[3px] pl2 placeholder:opacity-3 placeholder:font-medium rounded glass-background input-reset h-12 w-full`}
                      aria-label="Default select example">
                      <option selected className="text-black font-medium">
                        {/* {fieldOne.placeholder} */}
                      </option>
                      {fieldOne.options.map((opt, key) => (
                        <option key={key} className="text-black" value={opt}>
                          {opt}
                        </option>
                      ))}
                    </select>
                  )}
                  {fieldOne.type === 'fileUpload' && (
                    <div>
                      <Upload
                        getUploadedFields={getUploadedFields}
                        refName={fieldOne.name}
                        buttonText={fieldOne.label}
                        types={['pdf', 'image/png', 'image/jpeg', 'text/csv']}
                        // setFile={(file) => fieldOne.set(file)}
                      />
                    </div>
                  )}
                  {!fieldOne.type && (
                    <input
                      id={fieldOne.id}
                      placeholder={fieldOne.placeholder}
                      value={fieldOne.value}
                      onChange={(event) => {
                        fieldOne.set(event.currentTarget.value);
                        checkForErrors({
                          [fieldOne.name]: event.currentTarget.value,
                        });
                      }}
                      className="mt-[3px] pl2  placeholder:opacity-3 placeholder:font-medium rounded glass-background input-reset h-12 w-full"
                    />
                  )}
                </div>
                {fieldOne.error && <small className="db mt-2 tl red">{fieldOne.errorText}</small>}
              </div>
            );
          }
        })}

        <div className="flex mt-3 tl pv2">
          <CheckBoxInput
            id="terms-checkbox"
            reverseLayout
            defaultValue={termsCondition}
            onClick={() => {
              trackAction({
                action: AMPLITUDE_ACTIONS.CLICK,
                event: AMPLITUDE_EVENTS.CHECKOUT_TERMS_AND_CONDITIONS,
                eventData: {
                  eventId: event?._id,
                  eventName: event?.name,
                },
              });
              setTermsCondition(!termsCondition);
            }}>
            By checking this box you agree to our{' '}
            <span
              onClick={() => {
                setModalIsOpen(true);
              }}
              className="underline-hover ">
              Ticketing Terms <span className="">and</span> Conditions
            </span>
          </CheckBoxInput>
        </div>
        {termsConditionError && <small className="db tl red">You must agree to our terms to continue</small>}

        {
          <div>
            <hr className="my-6" />
            <div className="dib pb-6 py-3 flex flex-col  w-100">
              {!waitlist && (
                <>
                  <ApplePayComponent
                    total={total}
                    currency={currency}
                    getApplePayOrder={getApplePayOrder}
                    setMode={setMode}
                    clientSecret={clientSecret}
                    event={event}
                    utm_medium={utm_medium}
                    utm_source={utm_source}
                  />
                  <span id="form-total" className="fl pt2 f3-ns f4 db font-semibold">
                    Total: {formatPrice(total.toString(), false, currency)}
                  </span>
                </>
              )}

              <div className="flex gap-3  mx-auto md:mr-0 w-full md:w-auto py-5 flex-col-reverse md:flex-row ">
                <button
                  onClick={(e) => {
                    trackAction({
                      action: AMPLITUDE_ACTIONS.BACK,
                      event: AMPLITUDE_EVENTS.CHECKOUT_BACK,
                      eventData: {
                        eventId: event?._id,
                        eventName: event?.name,
                      },
                    });

                    e.preventDefault();
                    setMode(CheckoutFlow.TICKET_SELECTION);
                    scroll();
                  }}
                  className=" d:mr-0 py-3 px-5 md:text-3xl md:py-2 md:px-0 md:w-[150px] font-grotesk w-[230px] mx-auto leading-tight rounded-full hover:underline font-extrabold  text-white">
                  Back
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    trackAction({
                      action: AMPLITUDE_ACTIONS.CLICK,
                      event: AMPLITUDE_EVENTS.CHECKOUT,
                      opts: {
                        text: waitlist ? 'Join Waitlist' : isOffer ? `Place hold` : `Checkout`,
                      },
                      eventData: {
                        eventId: event?._id,
                        eventName: event?.name,
                      },
                    });
                    onCheckout();
                  }}
                  className="  md:mr-0 mt-4 md:mt-0 py-3 px-5 md:text-3xl md:py-2 md:px-0 md:min-w-[90px] md:w-full font-grotesk w-[230px] mx-auto leading-tight rounded-full  bg-green font-extrabold  text-white">
                  {waitlist ? 'Join Waitlist' : isOffer ? `Place hold` : `Checkout`}
                </button>
              </div>
            </div>
          </div>
        }
      </form>
    </div>
  );
};
